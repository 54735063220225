import React from 'react';
import Typography from '@material-ui/core/Typography';
import {userTranslate} from 'react-admin'
import {OrderStatus} from '../../util/constant';

export var orderRunningStatusValues = [];
orderRunningStatusValues.push({id: 0, name: "Tous"});
orderRunningStatusValues.push({id: OrderStatus.CREATED, name: "En attente"});
orderRunningStatusValues.push({id: OrderStatus.ASSIGNED, name: "Attribuée au chauffeur"});
orderRunningStatusValues.push({id: OrderStatus.ACCEPTED, name: "Acceptée par chauffeur"});
orderRunningStatusValues.push({id: OrderStatus.TO_PICK, name: "En route pour la prise en charge"});
orderRunningStatusValues.push({id: OrderStatus.AT_PICK, name: "À la prise en charge"});
orderRunningStatusValues.push({id: OrderStatus.ONBOARD, name: "Passager à bord"});
orderRunningStatusValues.push({id: OrderStatus.STARTED, name: "Commencée"});
// orderRunningStatusValues.push({id: OrderStatus.ENDED, name: "Arrivée"});

export var orderFinishedStatusValues = [];
orderFinishedStatusValues.push({id: 0, name: "Tous"});
orderFinishedStatusValues.push({id: OrderStatus.DENIED, name: "Aucun chauffeur"});
orderFinishedStatusValues.push({id: OrderStatus.FINISHED, name: "Complétée"});
orderFinishedStatusValues.push({id: OrderStatus.CANCELLED, name: "Annulée"});


export const OrderRunningStatusFilterField = ({ record }) => {
    var statusText = 'Tous';
    const status = parseInt(record.id);
    
    // if(status & OrderStatus.ENDED) 
    //     statusText = "Arrivée";
    // else
        if(status & OrderStatus.STARTED) 
            statusText = "Débutée";
    else
        if(status & OrderStatus.ONBOARD) 
            statusText = "Passager à bord";   
    else
        if(status & OrderStatus.TO_PICK) 
            statusText = "En route pour la prise en charge";
    else
        if(status & OrderStatus.AT_PICK) 
            statusText = "À la prise en charge"; 
    else
        if(status & OrderStatus.ACCEPTED) 
            statusText = "Acceptée par chauffeur";
    else
        if(status & OrderStatus.ASSIGNED) 
            statusText = "Attribuée au chauffeur";
    else
        if(status & OrderStatus.CREATED) 
            statusText = "En attente";
    return <div>
         <Typography variant="body2">
        {statusText}
        </Typography>
    </div>;
};

export const OrderFinishedStatusFilterField = ({ record }) => {
    var statusText = 'Tous';
    const status = parseInt(record.id);
    if(status & OrderStatus.CANCELLED) 
        statusText = "Annulée";
    else        
    if(status & OrderStatus.FINISHED) 
        statusText = "Complétée";
    else
        if(status & OrderStatus.DENIED) 
            statusText = "Aucun chauffeur";
    
    return <div>
         <Typography variant="body2">
        {statusText}
        </Typography>
    </div>;
};

const OrderStatusField = ({ record = {}, label}) => {
    var statusText = 'Inconnu';
    const status = parseInt(record.status);
    if(status & OrderStatus.CANCELLED) {
        const cancelType = parseInt(record.cancel_type);
        if(cancelType < 20)
            statusText = "Annulée";
        if(cancelType >= 20 && cancelType < 30)
            statusText = "À assigner";
        else
            statusText = "Annulée";
    }
    else        
    if(status & OrderStatus.FINISHED) 
        statusText = "Complétée";
        else
    if(status & OrderStatus.ENDED) 
        statusText = "Arrivée";
    else
        if(status & OrderStatus.STARTED) 
            statusText = "Débutée";
    else
        if(status & OrderStatus.ONBOARD) 
            statusText = "Passager à bord";   
    else
        if(status & OrderStatus.TO_PICK) 
            statusText = "En route pour la prise en charge";
    else
        if(status & OrderStatus.AT_PICK) 
            statusText = "À la prise en charge"; 
    else
        if(status & OrderStatus.ACCEPTED) 
            statusText = "Acceptée par chauffeur";
    else
        if(status & OrderStatus.DENIED) 
            statusText = "Aucun chauffeur";
    else
        if(status & OrderStatus.ASSIGNED) 
            statusText = "Attribuée au chauffeur";
    else
        if(status & OrderStatus.CREATED) 
            statusText = "En attente";
    return <div>
         <Typography variant="body1" color="secondary">{statusText}</Typography>
    </div>;
}

OrderStatusField.defaultProps = {
    addLabel: true,
};

export default OrderStatusField;
