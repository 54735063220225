
import React, {Link} from 'react';
import { ReferenceField, Pagination, Labeled, ReferenceManyField, Datagrid, SimpleShowLayout,TextField, Show, useTranslate } from 'react-admin';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'
import {OrderSubTypeField, OrderStatusField, FormattedDateField, OrderTypeField, OrderOptionsField, PaymentTypeField,
    DistanceField, DurationField} from '../../control/field/index'

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
});

// const changePassword = () => {
//     // window.location.href = '/#/change-password';
// }

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_running.show')}: #{record ? `${record.order_number}` : ''}</span>;
    
};

const ClientFullNameField = ({ record }) => <span>{record.c_first_name} {record.c_last_name}</span>;
ClientFullNameField.defaultProps = {
    addLabel: true,
};
const DriverFullNameField = ({ record }) => <span>{record.d_first_name} {record.d_last_name}</span>;
DriverFullNameField.defaultProps = {
    addLabel: true,
};
const CreatorFullNameField = ({ record }) => {
    if(typeof(record) === 'undefined' || record === 'undefined') return null;
    if(record.creator_id === "undefined" || record.creator_id === null || record.creator_id === '') return null;
    return <Labeled label="Créée par le chauffeur">
    <span>{record.cr_first_name} {record.cr_last_name}</span>
    </Labeled>
    ;
}
CreatorFullNameField.defaultProps = {
    addLabel: false,
};

const CommissionCreatorField = ({ record }) => {
    if(typeof(record) === 'undefined' || record === 'undefined') return null;
    if(record.creator_id === "undefined" || record.creator_id === null || record.creator_id === '') return null;
    return <Labeled label="Commission créateur chauffeur(€)">
    <Typography variant="body1">{record.commission_creator}</Typography>
    </Labeled>
    ;
}
CommissionCreatorField.defaultProps = {
    addLabel: false,
};

// const ShareClientsField = ({record, basePath}) => {
//     if(typeof(record) === 'undefined' || record === 'undefined') return null;
//     if(record.order_sub_type !== "8") return null;
//     return (<div>
//     <Labeled label="Passagers de covoiturage">
//     <ReferenceManyField reference="order_share" basePath={basePath} filter={{going_order_id: record.id}} addLabel={true} label="Passagers de covoiturage">
//         <Datagrid>
//             <ClientFullNameField label="Nom"/>
//             <TextField source="from_address" label="Adresse de départ"/>
//             <TextField source="to_address" label="Adresse d'arrivée"/>
//             <TextField source="total" label="Prix(€)"/>
//             <OrderStatusField source="status" label = "État"/>
//         </Datagrid>
//     </ReferenceManyField>
//     </Labeled>
//     </div>
//     );
// };
const ShareClientsField = ({record = {}, basePath}) => {
    if(typeof(record) === 'undefined' || record === 'undefined') return null;
    if(record.order_sub_type !== "8") return null;
    return (<div>
     {/* <Labeled label="Passagers de covoiturage"> */}
     <Typography variant="body2" color="secondary">Passagers de covoiturage</Typography>
     {/* filter={{going_order_id: record.id}}  */}
    <ReferenceManyField perPage={20} reference="order" basePath={basePath} filter={{going_order_id: record.id}} addLabel={true} label="Passagers de covoiturage">
        <Datagrid>
            <ClientFullNameField label="Nom du client"/>
            <TextField source="from_address" label="Adresse de départ"/>
            <TextField source="to_address" label="Adresse d'arrivée"/>
            <TextField source="total" label="Prix"/>
            {/* <FormattedDateField source="at_pick_time" format="format_date_time" label="Sur place"/> */}
            
            {/* <FormattedDateField source="at_pick_time" format="format_date_time" label="Sur place"/> */}
            <FormattedDateField source="start_time" format="format_date_time" label="Début"/>
            <FormattedDateField source="end_time" format="format_date_time" label="Arrivée"/>
        </Datagrid>
    </ReferenceManyField>
    {/* </Labeled> */}
    </div>
    );
};

ShareClientsField.defaultProps = {
    addLabel: false,
};
// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowOrderRunning = ({staticContext, ...props}) => {
    const classes = useStyles();
    return(
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            
        <SimpleShowLayout>
            <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/>
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            <CreatorFullNameField/>
            <div/>
            <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock}/>
            <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
            <OrderSubTypeField source="order_sub_type" label= "Covoiturage" className={classes.inlineBlock}/>
            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type">
                <TextField source="name" />
            </ReferenceField>
            {/* <div/>
            <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
            <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
            <div/> */}
            <div/>
            <TextField source="luggage_count" label = "Nombre de bagages" className={classes.inlineBlock}/>
            <TextField source="passenger_count" label = "Nombre de passagers" className={classes.inlineBlock}/>
            <div/>

            <ClientFullNameField label="Client"/>
            <TextField source="from_address" label = "Adresse de départ"/>
            <TextField source="to_address" label = "Adresse d'arrivée"/>

            <div/>
                <DistanceField label="Distance" className={classes.inlineBlock}/>
                <DurationField label="Durée" className={classes.inlineBlock}/>
                <div/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandée"/>
            <OrderOptionsField label="Options du trajet"/>
            {/* <div/>
            <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
            <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/>
            <div/> */}
            <DriverFullNameField label="Chauffeur"/>
            {/* <ReferenceField label="Client" source="client_id" reference="client" link="show">
                <TextField source="email" />
            </ReferenceField>

            <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="show">
                <TextField source="email" />
            </ReferenceField> */}
            <div/>
            <TextField source="total" label="Prix(€)" className={classes.inlineBlock}/>
            <TextField source="commission_amount" label="Commission EDD(€)" className={classes.inlineBlock}/>
            <CommissionCreatorField className={classes.inlineBlock}/>
            <TextField source="driver_amount" label="Commission chauffeur(€)" className={classes.inlineBlock}/>
            <PaymentTypeField label="Mode de paiement"/>
            <div/>
            <br/>
            <ShareClientsField/>
            {/* <TextField source="pt_name" label="Mode de paiement"/> */}
            {/* <TextField source="driver.email" label="Chauffeur"/> */}
            
            {/* <div/>
            <Divider/> */}
            <br/>
            <Typography variant="body2" color="secondary">Déroulement</Typography>
            <div/>
            <FormattedDateField source="start_time" format="format_date_time" label="Heure de début" className={classes.inlineBlock}/>
            {/* <FormattedDateField source="at_pick_time" format="format_date_time" label="Chauffeur sur place" className={classes.inlineBlock}/> */}
            {/* <FormattedDateField source="onboard_time" format="format_date_time" label="Passager à bord" className={classes.inlineBlock}/> */}
            <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/>
            
            {/* <Typography variant="body2" color="secondary">Covoiturage</Typography>
                <div/>
                <ReferenceManyField pagination={<Pagination />} reference="order" target="going_order_id" addLabel={false}>
                    
                    <Datagrid>
                        <TextField label="Adresse de départ" source="from_address"/>
                        <TextField label="Adresse d'arrivée" source="to_address"/>
                        <TextField label="Adresse d'arrivée" source="to_address"/>
                        <ClientFullNameField label="Client"/>
                        <TextField source="total" label="Prix(€)"/>
                        
                    </Datagrid>
                </ReferenceManyField>
             */}
        </SimpleShowLayout>
    </Show>
)};


export default ShowOrderRunning;