import React, { Fragment } from 'react';
import {ReferenceInput,AutocompleteInput, Responsive, Filter, TextInput, SelectInput, useTranslate, List, Datagrid, TextField, DateField, ReferenceField } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {OrderTypeField, OrderTypeFilterField, OrderStatusField, OrderRunningStatusFilterField, FormattedDateField, OrderSubTypeField} from '../../control/field/index'
import {orderRunningStatusValues} from '../../control/field/order-status-field'
import Typography from '@material-ui/core/Typography';
import { useEffect, useRef } from 'react';
import { useRefresh } from 'react-admin';
import Pusher from 'pusher-js';
import {BACKEND_UMS_URL, BACKEND_OMS_URL, PUSHER_API_KEY, PUSHER_CLUSTER,
    PUSHER_CHANNEL_USER, PUSHER_CHANNEL_ORDER, EVENT_ONLINE_DRIVERS_CHANGED, EVENT_ORDER_NEW,
} from '../../util/variant'


const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_unassigned.list')}</span>;
};

const ClientFullNameField = ({ record }) => <Typography variant="body1">{record.c_first_name} {record.c_last_name}</Typography>;
// ClientFullNameField.defaultProps = {
//     addLabel: true,
// };
const DriverFullNameField = ({ record }) => <Typography variant="body1">{record.d_first_name} {record.d_last_name}</Typography>;
// DriverFullNameField.defaultProps = {
//     addLabel: true,
// };
const CreatorFullNameField = ({ record }) => <Typography variant="body1">{record.cr_first_name} {record.cr_last_name}</Typography>;
// CreatorFullNameField.defaultProps = {
//     addLabel: true,
// };

const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Nº de commande" source="order_number" alwaysOn resettable/>
        <ReferenceInput label="Email client" source="client_id" reference="client" perPage={99999} alwaysOn resettable
        emptyText="Tous"
        >
            <AutocompleteInput  optionText="email"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput>
        {/* <ReferenceInput label="Email chauffeur" source="driver_id" reference="driver" perPage={99999} alwaysOn emptyText="Tous" resettable> 
            <AutocompleteInput optionText="email"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput> */}
        {/* <SelectInput label="État" source="status" choices={orderRunningStatusValues} 
        optionText={<OrderRunningStatusFilterField />}
        alwaysOn/> */}
        <TextInput label="Adresse de départ" source="from_address" resettable/>
        <TextInput label="Adresse d'arrivée" source="to_address" resettable/>
        
        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/> */}
        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
         */}
        {/* <TextInput label="Ville" source="create_time" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/> */}
        {/* <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        {/* <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Validation" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>
);

const initNewOrderPusher = (callback)=>{
    console.log('Dashboard Init new order pusher');
    const onlineDriverPusher = new Pusher(PUSHER_API_KEY, {
        cluster: PUSHER_CLUSTER,
        encrypted: true
      });
      const channel = onlineDriverPusher.subscribe(PUSHER_CHANNEL_ORDER);
      channel.bind(EVENT_ORDER_NEW, data => {
        // this.setState({ chats: [...this.state.chats, data], test: '' });
        console.log('Dashboard new order');
        
        if(localStorage.getItem('session_id')){
            callback();
        }
            
      });
}

function useRecursiveTimeout(callback, delay) { 
    const savedCallback = useRef(callback)

    useEffect(() => {
      savedCallback.current = callback  
    }, [callback])

    useEffect(() => { 
      let id    
      function tick() {
        const ret = savedCallback.current()

        if (ret instanceof Promise) {
          ret.then(() => {
            if (delay !== null) {
              id = setTimeout(tick, delay)
            }
          })
        } else {
          if (delay !== null) {
            id = setTimeout(tick, delay)
          }
        }
      }
      if (delay !== null) {
        id = setTimeout(tick, delay)
        return () => id && clearTimeout(id)
      }
    }, [delay])
  }
const ListOrderUnassigned = (props) => {
    const refresh = useRefresh()
      useRecursiveTimeout(() => refresh(), 15000) 
    // initNewOrderPusher(() => refresh);
    return  <Responsive xsmall={
      <List title={<Title/>} {...props} 
    filters = {<ListFilter/>}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="edit">
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            {/* <TextField source="order_number" label="Nº de commande"/> */}
            {/* <OrderTypeField source="order_type" label= "Type de commande"/> */}
            {/* <OrderSubTypeField source="order_sub_type" label= "Covoiturage"/>
            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type">
                <TextField source="name" />
            </ReferenceField> */}
            {/* <CreatorFullNameField label="Créée par"/> */}
            {/* <ClientFullNameField label="Client"/> */}
            {/* <TextField source="c_last_name" label="Nom du client"/>
            <TextField source="c_first_name" label="Prénom du client"/> */}
            {/* <TextField source="from_address" label="Adresse de départ"/>
            <TextField source="to_address" label="Adresse d'arrivée"/> */}
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandée"/>
            {/* <TextField source="d_last_name" label="Nom du chauffeur"/>
            <TextField source="d_first_name" label="Prénom du chauffeur"/> */}
            {/* <TextField source="original_total" label="Prix original(€)"/> */}
            <TextField source="total" label="Prix(€)"/>
            <OrderStatusField source="status" label = "État"/>
            <TextField source="driver_name" label="Chauffeur assigné"/>
            {/* <TextField source="creator.email" label="Créateur"/> */}
            {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
            {/* <DateField source="update_time" label="Date de modification"  locales="fr-FR"/> */}
        </Datagrid>
    </List>
    }

    small={
      <List title={<Title/>} {...props} 
    filters = {<ListFilter/>}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="edit">
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            <TextField source="order_number" label="Nº de commande"/>
            {/* <OrderTypeField source="order_type" label= "Type de commande"/> */}
            <OrderSubTypeField source="order_sub_type" label= "Covoiturage"/>
            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type">
                <TextField source="name" />
            </ReferenceField>
            {/* <CreatorFullNameField label="Créée par"/> */}
            {/* <ClientFullNameField label="Client"/> */}
            {/* <TextField source="c_last_name" label="Nom du client"/>
            <TextField source="c_first_name" label="Prénom du client"/> */}
            <TextField source="from_address" label="Adresse de départ"/>
            <TextField source="to_address" label="Adresse d'arrivée"/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandée"/>
            {/* <TextField source="d_last_name" label="Nom du chauffeur"/>
            <TextField source="d_first_name" label="Prénom du chauffeur"/> */}
            {/* <TextField source="original_total" label="Prix original(€)"/> */}
            <TextField source="total" label="Prix(€)"/>
            <OrderStatusField source="status" label = "État"/>
            <TextField source="driver_name" label="Chauffeur assigné"/>
            {/* <TextField source="creator.email" label="Créateur"/> */}
            {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
            {/* <DateField source="update_time" label="Date de modification"  locales="fr-FR"/> */}
        </Datagrid>
    </List>
    }

    medium={
      <List title={<Title/>} {...props} 
    filters = {<ListFilter/>}
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="edit">
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            <TextField source="order_number" label="Nº de commande"/>
            <OrderTypeField source="order_type" label= "Type de commande"/>
            <OrderSubTypeField source="order_sub_type" label= "Covoiturage"/>
            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type">
                <TextField source="name" />
            </ReferenceField>
            <CreatorFullNameField label="Créée par"/>
            <ClientFullNameField label="Client"/>
            {/* <TextField source="c_last_name" label="Nom du client"/>
            <TextField source="c_first_name" label="Prénom du client"/> */}
            <TextField source="from_address" label="Adresse de départ"/>
            <TextField source="to_address" label="Adresse d'arrivée"/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandée"/>
            {/* <TextField source="d_last_name" label="Nom du chauffeur"/>
            <TextField source="d_first_name" label="Prénom du chauffeur"/> */}
            <TextField source="original_total" label="Prix original(€)"/>
            <TextField source="total" label="Prix(€)"/>
            <OrderStatusField source="status" label = "État"/>
            <TextField source="driver_name" label="Chauffeur assigné"/>
            {/* <TextField source="creator.email" label="Créateur"/> */}
            {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
            {/* <DateField source="update_time" label="Date de modification"  locales="fr-FR"/> */}
        </Datagrid>
    </List>
    }
    />
    
};

export default ListOrderUnassigned;

