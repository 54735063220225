
import React from 'react';
import { number, TextField, FormTab, TabbedForm, SelectInput, TextInput, Edit, SimpleForm, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import Typography from '@material-ui/core/Typography';

import {GENDER_MALE, GENDER_FEMALE, USER_TYPE_AUTO_ENTERPRENER, USER_TYPE_COMPANY_AND_DRIVER} from '../../util/constant';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: {
        // paddingLeft: 50,
        color: '#F4004E',
    },

    noColorLabel: {
        paddingLeft: 50,  
    },

    colorValue: {
        color: '#F4004E',
        paddingLeft:5
    },

    supplementText: {
        color: '#F4004E',
    },

    noHeader:{
        display:'none',
        // height:'1px'
    }
    
});

const styles = {
    flex: { display: 'flex', },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
    button:{ flex: 1, marginTop: '1em'},
};


const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.edit')}: {record ? `${record.email}` : ''}</span>;
};

var genders = [];
genders.push({id: GENDER_MALE, name: 'Monsieur'});
genders.push({id: GENDER_FEMALE, name: "Madame"});

var driverTypes = [];
driverTypes.push({id: USER_TYPE_AUTO_ENTERPRENER, name: "Auto-entreprise"});
driverTypes.push({id: USER_TYPE_COMPANY_AND_DRIVER, name: "Autre"});

const validatePostalCodeRange = (value, allValues) => {
  if (value < 1000 || value > 99000) {
      return 'Doit être en [01000, 99000]';
  }
  return [];
};

const validatePostalCode = [required(), number(), validatePostalCodeRange];

const EditDriver = ({permissions, ...props })  => {
    const classes = useStyles();
    return(
    <Edit title={<Title />} {...props} actions={<EditActions/>}>
         <TabbedForm redirect="list" 
                toolbar={<CustomToolbar/>}>
                    <FormTab label="Profil">
                        
                        <TextInput source="email" label="Email" validate={required()} disabled />
                        <TextInput source="mobile" label="Mobile" validate={required()} disabled/>
    
                        <SelectInput source="gender" label="Civilité" validate={required()} choices={genders}/>
                        <TextInput source="last_name" label="Nom" validate={required()}/>
                        <TextInput source="first_name" label="Prénom" validate={required()}/>
                    </FormTab>
                    <FormTab label="Informations de la société">
                        
                        <SelectInput source="user_type" label="Type d'entreprise" validate={required()} choices={driverTypes}/>
                        <TextInput source="company_name" label="Nom de la société" validate={required()}/>
                        <TextInput source="company_address" label="Adresse de la société" validate={required()}/>
                        <TextInput source="city" label="Ville" validate={required()}/>
                        <TextInput source="postal_code" label="Code postal" validate={validatePostalCode}/>
                    </FormTab>
                    

        </TabbedForm>
    </Edit>
);
};

export default EditDriver;