import React, { Fragment } from 'react';
import { Filter, TextInput, SelectInput, List, Datagrid, TextField, downloadCSV } from 'react-admin';
import BatchDeleteButton from '../../control/button/batch-delete-button';
import {OrderTypeField, OrderStatusField, FormattedDateField, OrderSubTypeField, OrderTypeFilterField, OrderSubTypeFilterField} from '../../control/field/index'
import { DateInput } from 'react-admin-date-inputs2';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import "moment/locale/fr";
import moment from 'moment';
import { orderSubTypes, orderTypes } from '../../util/constant';
import jsonExport from 'jsonexport/dist';

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
    </Fragment>
);

const Title = ({ record }) => {
    return <span>Paiements</span>;
};

const ClientFullNameField = ({ record }) => <span>{record.c_first_name} {record.c_last_name}</span>;
const DriverFullNameField = ({ record }) => <span>{record.d_first_name} {record.d_last_name}</span>;
const CreatorFullNameField = ({ record }) => <span>{record.cr_first_name} {record.cr_last_name}</span>;
// const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale='fr' label="Du jour" alwaysOn resettable>
                <DateInput source="from_date" label="Du jour" className="MuiFilledInput-root" 
                resettable 
            autoComplete="" options={{ format: 'DD/MM/yyyy', cancelLabel:"Annuler" }} parse={v => v && v.toISOString()}
                 providerOptions={{ utils: MomentUtils, locale: frLocale }}
            />
            
            </MuiPickersUtilsProvider>


            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale='fr' label="Au jour" alwaysOn resettable>
                <DateInput source="to_date" label="Au jour" className="MuiFilledInput-root" 
                resettable initialValue={new Date()}
            autoComplete="" options={{ format: 'DD/MM/yyyy', cancelLabel:"Annuler" }} parse={v => v && v.toISOString()}
                 providerOptions={{ utils: MomentUtils, locale: frLocale }}
            />
            
            </MuiPickersUtilsProvider>

        {/* <ReferenceInput label="Chauffeur" source="driver_id" reference="driver" perPage={99999} alwaysOn emptyText="Aucun" resettable> 
            <AutocompleteInput optionText="email"
                // optionText={choice =>
                //     `${choice.email}`
                // }
            />
        </ReferenceInput> */}
        {/* <SelectInput label="État" source="status" choices={orderRunningStatusValues} 
        optionText={<OrderRunningStatusFilterField />}
        alwaysOn/> */}

        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/> */}
        {/* <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
         */}
        {/* <TextInput label="Ville" source="create_time" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/> */}
        {/* <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}

        {/* <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Validation" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>
);

const exporter = driverPayments => {
    const dataForExport = driverPayments.map(driverPayment => {
        const { id, ...dataForExport } = driverPayment; // omit backlinks and author
        // postForExport.author_name = post.author.name; // add a field
        return dataForExport;
    });
    jsonExport(dataForExport, {
        headers: ['period', 'last_name', 'first_name', 'email', 'total', 
        'iban', ], // order fields in the export
        rename: ['Periode', 'Nom', 'Prenom', 'Email', 'Montant a payer(€)', 
        'IBAN'],
        verticalOutput: false,
        booleanTrueString: 'Oui',
        booleanFalseString: 'Non',
        // rowDelimiter: "\t",
        // forceDelimiter: true,
    }, (err, csv) => {
        downloadCSV(csv, 'payments'); // download as 'posts.csv` file
    });
};

// sort={{ field: 'last_name', order: 'DESC' }}

const ListPayment = (props) => (
    <List title={<Title/>} {...props}
    filters = {<ListFilter/>} 
        bulkActionButtons={<BulkActionButtons />} exporter={exporter}>
        <Datagrid rowClick="" >
            {/* <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/> */}
            <TextField source="last_name" label="Nom"/>
            <TextField source="first_name" label="Prénom"/>
            <TextField source="email" label="Email"/>
            <TextField source="total" label="Montant à payer(€)"/>
            <TextField source="iban" label="IBAN"/>
        </Datagrid>
    </List>
);

export default ListPayment;

