import React from 'react';
import { ArrayInput, SimpleFormIterator, Edit, NumberInput, TextInput, number, TextField, SimpleForm, required, TabbedForm, 
    FormTab, useTranslate } from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';
import Button from '@material-ui/core/Button';
import { ShowButton, SaveButton, Toolbar, TopToolbar } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const EditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        {/* <ShowButton basePath='/view-my-profile' record={data} /> */}
        {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </TopToolbar>
);

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Enregistrer"
            redirect="/config"
            submitOnEnter={true}
        />
        
    </Toolbar>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('menu.settings')}</span>;
};

const validateNotSmallerThanOne = (value, allValues) => {
    if (value < 1) {
        return 'Doit ne pas être moins que 1';
    }
    return [];
};

const validatePositive = (value, allValues) => {
    if (value <= 0) {
        return 'Doit être plus grand que 0';
    }
    return [];
};

const validateNotPositive = (value, allValues) => {
    if (value < 0) {
        return 'Doit être positif';
    }
    return [];
};

// const validateGreaterThanZero = (value, allValues) => {
//     if (value < 1) {
//         return 'Doit être au moins 1';
//     }
//     return [];
// };

const validatePositiveNumber = [required(), number(), validatePositive];
const validateNotNegativeNumber = [required(), number(), validateNotPositive];
const validateNotSmallerThanOneNumber = [required(), number(), validateNotSmallerThanOne];
// const validateMaxCount = [required(), number(), validateGreaterThanZero];

// const EditConfig = ({ staticContext, ...props }) => {
    const EditConfig = props => {
    return (
        <Edit
            actions = {<EditActions/>}
            id='noid'
            resource="config"
            basePath="/config"
            redirect="false"
            title={<Title/>}
            {...props}
        >

        {/* <SimpleForm redirect="list">
                
            <TextInput source="currency_code" label="Code de monnaie"/>
            

        </SimpleForm> */}
        <TabbedForm toolbar={<EditToolbar />}>
            <FormTab label="Généraux">
                {/* <TextInput source="currency_code" label="Code de monnaie"/>
                <TextInput source="currency_symbol" label="Symbole de monnaie"/> */}
                {/* <TextInput source="unit_time" label="Symbole de monnaie"/> */}
                <NumberInput source="commission" label="Commission EDD (%)" validate={validateNotNegativeNumber} style={{'width':'300px'}}/>
                <NumberInput source="vat_2" label="TVA Auto-entreprise(%)" validate={validateNotNegativeNumber} style={{'width':'300px'}}/>
                <NumberInput source="vat_6" label="TVA Autre(%)" validate={validateNotNegativeNumber} style={{'width':'300px'}}/>
                <NumberInput source="doc_validation_warning_before" label="Alerting documents expirés (jours)" 
                initialValue={1} style={{'width':'300px'}}
                validate={validateNotSmallerThanOneNumber}/>
            </FormTab>
            <FormTab label="Commande">
              
                {/* <TextInput fullWidth source="manual_dispatch_postal_codes" style={{'width':'50%'}} helperText="Exemple: 13001-13999, 14000, 14007. Tandis que 13001-13999 pour les codes postaux de 13001 à 13000. 14000 et 14007 sont des valuers individuelles. Séparées par des virgules"
                    label="Zones (codes postaux) pour le dispatching manuel"/> */}
                <NumberInput source="timeout_order_wait_for_accept" label="Délai d'acceptation (secondes)" validate={validatePositiveNumber} style={{'width':'250px'}}/>
                
                
                {/* <Typography variant="h6" gutterBottom>Immédiate</Typography> */}
                <NumberInput source="pick_up_radius" label="Rayon (km) autour de la position courante des clients" validate={required()} style={{'width':'450px'}}/>
                {/* <Typography variant="h6" gutterBottom>Réservation à l'avance</Typography> */}
                <NumberInput source="soonest_reservation_time" label="Heure de réservation au plus tôt (minutes)" validate={validateNotNegativeNumber} style={{'width':'450px'}}/>
                <NumberInput source="latest_reservation_time" label="Heure de réservation au plus tard (minutes)" validate={validateNotNegativeNumber} style={{'width':'450px'}} />
                
                
                
                <NumberInput source="wait_fee" label="Frais d'attente (€/minute)" validate={validateNotNegativeNumber} style={{'width':'450px'}} />
                <NumberInput source="free_wait_min" label="Temps d'attente gratuit (minutes)" validate={validateNotNegativeNumber} style={{'width':'450px'}} />
            </FormTab>

            <FormTab label="Covoiturage">
                <NumberInput source="carsharing_additional_discount" label="Remise supplémentaire (en %) pour chaque client qui rejoint un trajet" style={{'width':'600px'}} validate={required()}/>
                <NumberInput source="join_before" label="Joindre avant la prise en charge (minutes)" validate={validateNotNegativeNumber} style={{'width':'450px'}} />
                <NumberInput source="soonest_share_time" label="Heure de réservation au plus tôt (minutes) - Covoiturage" validate={validateNotNegativeNumber} style={{'width':'450px'}}/>
                <NumberInput source="latest_share_time" label="Heure de réservation au plus tard (minutes) - Covoiturage" validate={validateNotNegativeNumber} style={{'width':'450px'}} />
            </FormTab>

            <FormTab label="Code de réduction">
                <NumberInput source="order_count_based_promo_code_order_count" label="Nombre de courses min. pour bénéficier d’une remise" style={{'width':'500px'}} validate={required()}/>
                <NumberInput source="order_count_based_promo_code_value" label="Valeur remise (%)" validate={required()}/>
                
            </FormTab>
            {/* <FormTab label="Comptes">
                <TextInput source="system.mail.sender" label="Expéditeur" validate={required()}/>
                <TextInput source="system.mail.receiver" label="Destinataire" validate={required()}/>
            </FormTab> */}


            {/* <FormTab label="Coordonnées EDD">
                <TextInput source="company_name" label="Nom" validate={required()} fullWidth/>
                <TextInput source="company_address_line1" label="Adresse ligne 1" validate={required()} fullWidth multiline/>
                <TextInput source="company_address_line2" label="Adresse ligne 2" validate={required()} fullWidth multiline/>
                <TextInput source="company_email" label="Email"/>
                <TextInput source="company_phone" label="Téléphone"/>
                <TextInput source="contact_email" label="Email de contact"/>
                <TextInput source="register_email" label="Email pour l'inscription"/>
                
            </FormTab> */}

            
        </TabbedForm>
            
          
        </Edit>
    );
};

export default EditConfig;
