import React from 'react';
import Typography from '@material-ui/core/Typography';
import { DateField} from 'react-admin';
import {Status, DataStatus, FALSE, TRUE, USER_TYPE_AUTO_ENTERPRENER,
    ACTIVITY_TYPE_TAXI, ACTIVITY_TYPE_VTC, ACTIVITY_TYPE_TPMR} from '../../util/constant'
import {DocDateEditButton} from '../button/index'
import {ImageViewer} from '../viewer/index'
import { makeStyles } from '@material-ui/core/styles';
import * as Constant from '../../util/constant'

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040',},

    colorPrimary: {color:'#c30040',},
    
    noColorLabel: {paddingLeft: 50,  
    },

    colorValue: {color: '#c30040',paddingLeft:5},

    supplementText: {color: '#c30040',},

    noHeader:{display:'none',
    // height:'1px'
    },

    image:{width:'200px',height:'200px',}
    
});

export const DocNameField = ({record, label}) => {
    // const classes = useStyles();
    console.log('Doc record: ' + JSON.stringify(record));
    // if(typeof(record) !== 'undefined' && typeof(record.name) !== 'undefined'){
    //     const nameObj = JSON.parse(record.name);
    //     // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
    //     // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
    //     return <div><span>{nameObj.fr}</span></div>;
    // }
    if(typeof(record) !== 'undefined' && typeof(record.doc_name) !== 'undefined'){
        // const nameObj = JSON.parse(record.name);
        // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
        // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
        return <div><span>{record.doc_name}</span></div>;
    }
    return <div/>;
    
}

DocNameField.defaultProps = {
    addLabel:true
}

export const FileCountField = ({record, label}) => {
    const classes = useStyles();
    console.log('Doc record: ' + JSON.stringify(record));
    if(typeof(record) !== 'undefined' && typeof(record.name) !== 'undefined'){
        return <div><span className={classes.colorValue}>{record.files.length}</span></div>
    }
    
    return <div><span className={classes.colorValue}>0</span></div>
    
}

FileCountField.defaultProps = {
    addLabel:true
}

export const DriverCompanyTypeField = ({ record = {}, label}) => {
    return <div>
        <Typography variant="body1">
            {record.user_type == USER_TYPE_AUTO_ENTERPRENER? 'Auto-entreprise': 'Autre'}
        </Typography>
    </div>;
}

DriverCompanyTypeField.defaultProps = {
    addLabel: true,
};

export const DocStatusReasonField = ({ record = {}, label}) => {
    const classes = useStyles();
    const statusData = record.status_data;
    if(typeof(statusData) !== 'undefined' && statusData !== null && statusData !== ''){
        const statusObj = JSON.parse(statusData); 
        // return <span>{statusObj.reason}</span>;
        return <Typography variant="body1" className={classes.colorPrimary}>{statusObj.reason}</Typography>
    }
    //     return <Typography variant="body2">
    //     {statusData.reason}
    // </Typography>
    return <span></span>;
}

DocStatusReasonField.defaultProps = {
    addLabel: true,
};

export const ClientDataField = ({ record = {}, source, label}) => {
    const client = record.client;
    if(typeof(client) !== 'undefined' && client !== null && client !== ''){
        const clientObj = JSON.parse(client); 
        return <Typography variant="body1">{clientObj[source]}</Typography>
    }
    
    return <span></span>;
}

ClientDataField.defaultProps = {
    addLabel: true,
};

export const DriverDataField = ({ record = {}, source, label}) => {
    const driver = record.driver;
    if(typeof(driver) !== 'undefined' && driver !== null && driver !== ''){
        const driverObj = JSON.parse(driver); 
        return <Typography variant="body1">{driverObj[source]}</Typography>
    }
    
    return <span></span>;
}

DriverDataField.defaultProps = {
    addLabel: true,
};


export const ValidityDateField = ({ record = {}, label}) => {
    const classes = useStyles();
    return record.date_id !== "" ? <div><br/>
            <Typography variant="body1" className={classes.noColorLabel}>
            Date de validité: {record.date !== "" ? <DateField className={classes.colorValue} source="date" record={record} label=""  locales="fr-FR"/> : '--'}
            <DocDateEditButton record={record}/>
        </Typography><br/>
    </div> : '';
}

export const PhotoField = ({ record = {}, source, label}) => {
    // const classes = useStyles();
    return <ImageViewer files={[]} fileUrl={record[source]} title={label} width='60' height='60' round='50%'/>
        
}

export const DriverCompanyTypeFilterField = ({ record }) => {
    return <span>{record.id == USER_TYPE_AUTO_ENTERPRENER ? 'Auto-entreprise' : 'Autre'}</span>;
};

export const PaymentTypeField = ({ record }) => {
    return <span>{record.payment_type_id === Constant.MODE_PAYMENT_CARD ? 'Carte bleu' : record.payment_type_id === Constant.MODE_PAYMENT_CARD_ON_BOARD ? 'Carte bleu à bord': 'En espèces'}</span>;
};
PaymentTypeField.defaultProps = {
    addLabel: true,
};

export const ActivityTypeField = ({ record = {}, label}) => {
    return <div>
        <Typography variant="body1">
            {record.activity_type == ACTIVITY_TYPE_TAXI? 'Taxi' : (record.activity_type == ACTIVITY_TYPE_VTC ? "VTC" : "TPMR")}
        </Typography>
    </div>;
}

ActivityTypeField.defaultProps = {
    addLabel: true,
};

export const OrderOptionsField = ({ record = {}, label}) => {
    console.log("Options: " + record.options);
    if(record === 'undefined') return <Typography variant="body1">
        <div>Aucune</div>
    </Typography>;
    var options = record.options;
    if(options === 'undefined' || typeof(options) === 'undefined' || options === null | options === "") 
    return <Typography variant="body1">
        <div>Aucune</div>
    </Typography>;
    options = JSON.parse(options);
    if(options.length === 0) return <Typography variant="body1">
    <div>Aucune</div>
</Typography>;
    return <div>
        <Typography variant="body1">
        {options.map(option => option.name).join(', ')}
        </Typography>
    </div>;
}

OrderOptionsField.defaultProps = {
    addLabel: true,
};

export const DistanceField = ({record, label}) => {
    // const classes = useStyles();
    console.log('Doc record: ' + JSON.stringify(record));
    // if(typeof(record) !== 'undefined' && typeof(record.name) !== 'undefined'){
    //     const nameObj = JSON.parse(record.name);
    //     // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
    //     // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
    //     return <div><span>{nameObj.fr}</span></div>;
    // }
    if(typeof(record) !== 'undefined' && typeof(record.distance) !== 'undefined'){
        // const nameObj = JSON.parse(record.name);
        // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
        // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
        var distance = (record.distance/1000).toFixed(2).replace(".", ",");
        return <div><span>{distance} km</span></div>;
    }
    return <div/>;
    
}

DistanceField.defaultProps = {
    addLabel:true
}

export const DurationField = ({record, label}) => {
    // const classes = useStyles();
    console.log('Doc record: ' + JSON.stringify(record));
    // if(typeof(record) !== 'undefined' && typeof(record.name) !== 'undefined'){
    //     const nameObj = JSON.parse(record.name);
    //     // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
    //     // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
    //     return <div><span>{nameObj.fr}</span></div>;
    // }
    if(typeof(record) !== 'undefined' && typeof(record.duration) !== 'undefined'){
        // const nameObj = JSON.parse(record.name);
        // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
        // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
        var hours = Math.floor(record.duration/60/60);
        if(hours === 0) return <div><span>{Math.floor(record.duration/60)} min</span></div>;
        var minutes = Math.floor(record.duration/60 - 60 * hours);
        return <div><span>{hours}h{minutes}</span></div>;
    }
    return <div/>;
    
}

DurationField.defaultProps = {
    addLabel:true
}