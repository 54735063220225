
import React from 'react';
import { ReferenceInput, Labeled, Datagrid, ReferenceManyField, CheckboxGroupInput, FormDataConsumer, ReferenceArrayInput,AutocompleteArrayInput,
    AutocompleteInput, TextInput, validate, minValue, NumberInput, Edit, ImageField, SimpleForm, TextField, SelectInput, RadioButtonGroupInput, required, ImageInput, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import { ListButton, Toolbar, SaveButton } from 'react-admin';
import DeleteButton from '../../control/button/delete-button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import {locationTypes} from '../../util/variant'
import * as Constant from '../../util/constant'
import {ClientField, OrderStatusField, DistanceField, DurationField, OrderOptionsField, PaymentTypeField,
    FormattedDateField, OrderTypeField,OrderSubTypeField, } from '../../control/field/index'
import Typography from '@material-ui/core/Typography';
import { resetSection } from 'redux-form';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },

    autoComplete:{
        width: '500px',
        marginLeft:'10px'
    }
});

const driverNameField = choice => choice != null ? `${choice.first_name} ${choice.last_name} (${choice.email})` : '';

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);
const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_unassigned.show')}: {record ? `${'#'+record.order_number}` : ''}</span>;
};

const ClientFullNameField = ({ record }) => <span>{record.c_first_name} {record.c_last_name}</span>;
ClientFullNameField.defaultProps = {
    addLabel: true,
};
const DriverFullNameField = ({ record }) => <span>{record.d_first_name} {record.d_last_name}</span>;
DriverFullNameField.defaultProps = {
    addLabel: true,
};

const DriverFullNameEmailField = ({ record }) => <span>{record.d_first_name} {record.d_last_name} ({record.d_email})</span>;
DriverFullNameEmailField.defaultProps = {
    addLabel: true,
};

const CreatorFullNameField = ({ record }) => {
    if(typeof(record) === 'undefined' || record === 'undefined') return null;
    if(record.creator_id === "undefined" || record.creator_id === null || record.creator_id === '') return null;
    return <div>
    <Labeled label="Créée par">
    <span style={{'color':'red'}}>{record.cr_first_name} {record.cr_last_name}</span>
    </Labeled>
    </div>;
}
CreatorFullNameField.defaultProps = {
    addLabel: false,
};

const ShareClientsField = ({record, basePath}) => {
    if(typeof(record) === 'undefined' || record === 'undefined') return null;
    if(record.order_sub_type !== "1") return null;
    return (<div>
    <Labeled label="Passagers de covoiturage">
    <ReferenceManyField reference="order_share" basePath={basePath} filter={{order_id: record.id}} addLabel={true} label="Passagers de covoiturage">
        <Datagrid>
            <ClientFullNameField label="Nom"/>
            <TextField source="from_address" label="Adresse de départ"/>
            <TextField source="to_address" label="Adresse d'arrivée"/>
            <TextField source="total" label="Prix(€)"/>
        </Datagrid>
    </ReferenceManyField>
    </Labeled>
    </div>
    );
};

ShareClientsField.defaultProps = {
    addLabel: false,
};

const CommissionCreatorField = ({ record }) => {
    if(typeof(record) === 'undefined' || record === 'undefined') return null;
    if(record.creator_id === "undefined" || record.creator_id === null || record.creator_id === '') return null;
    return <Labeled label="Commission créateur chauffeur(€)">
    <Typography variant="body1">{record.commission_creator}</Typography>
    </Labeled>
    ;
}
// CommissionCreatorField.defaultProps = {
//     addLabel: false,
// };


const DriverChosenField = ({record = {vehicle_type_id: -1}, rest}) => {
    const classes = useStyles();
    // if(record === null || record === 'undefined' || typeof(record) === 'undefined') return <span></span>;
    var vehicleTypeId = record.vehicle_type_id;
    console.log("Vehicle type id " + vehicleTypeId);
    return  <ReferenceInput
    className={classes.autoComplete}
    filter={{vehicle_type_id: vehicleTypeId}}
    reference="driver" source="driver_id_chosen" perPage={10000}>
        <AutocompleteInput optionText={driverNameField}/>
        </ReferenceInput>;
}

DriverChosenField.defaultProps = {
    addLabel: false,
};


const DispatchModeField = ({ record }) => {
    if(typeof(record) === 'undefined' || record === 'undefined') return null;
    if(record.dispatch_mode === "undefined") return null;
    var mode = record.dispatch_mode === "1" ? "Favoris" :  record.dispatch_mode === "2" ? "Choisi" : "Automatique";
    
    return <div>
    <Labeled label="Mode d'attribution">
    <span style={{'color':'black'}}>{mode}</span>
    </Labeled>
    </div>;
}
DispatchModeField.defaultProps = {
    addLabel: false,
};

const AssignField = ({ record = {}, rest }) => {
    const classes = useStyles();
    // if(typeof(record) === 'undefined' || record === 'undefined')
    //     return null;
    const notCreatedByDriver = record.creator_id === "undefined" || record.creator_id === null || record.creator_id === '';
    return <div style={{'margin-left':'0px'}}>
        <Card>
        <Typography variant="body2" className={classes.colorPrimary} style={{'margin-left':'10px', 'margin-top':'10px'}}>Assigner la commande</Typography>
        
        {/* {record.driver_id === null &&
        <Typography variant="body2" className={classes.colorPrimary} style={{'margin-left':'10px', 'margin-top':'10px'}}>Assigner la commande</Typography>}
        
        {record.driver_id !== null &&
        <Typography variant="body2" className={classes.colorPrimary} style={{'margin-left':'10px', 'margin-top':'10px'}}>Assigner à nouveau</Typography>} */}

        {notCreatedByDriver &&
        <RadioButtonGroupInput source="dispatch_mode" 
        label="" choices={[
            {id : Constant.MODE_DISPATCH_DRIVER_FAVORITE, name: 'Chauffeur préféré'},
            {id : Constant.MODE_DISPATCH_DRIVER_CHOSEN, name: 'Chauffeur choisi'},
            {id : Constant.MODE_DISPATCH_AUTO, name: 'Automatique'},
        ]} style={{'margin-left':'10px'}}/>}

        {!notCreatedByDriver &&
        <RadioButtonGroupInput {...rest} source="dispatch_mode" label="" choices={[
            {id : Constant.MODE_DISPATCH_DRIVER_CHOSEN, name: 'Chauffeur choisi'},
            {id : Constant.MODE_DISPATCH_AUTO, name: 'Automatique'},
        ]} style={{'margin-left':'10px'}}/>}

            <FormDataConsumer subscription={{ values: true }} style={{'margin-top':'0px'}} >
                 {({ formData, ...rest }) => 
                    
                    formData.dispatch_mode === Constant.MODE_DISPATCH_DRIVER_FAVORITE && 
                    
                    <ReferenceInput label="À un chauffeur préféré du client" source="driver_id_fav" 
                    target="driver_id"
                    filter={{client_id: record.client_id, vehicle_type_id: record.vehicle_type_id}} {...rest} className={classes.autoComplete}
                    reference="favorite_driver">
                    {/* target="client_id"  */}
                <AutocompleteInput optionText={driverNameField} style={{'width':'800px'}}/>
            </ReferenceInput>
                    
                 }
             </FormDataConsumer>

             <FormDataConsumer subscription={{ values: true }} style={{'margin-top':'0px'}} >
                 {({ formData, ...rest }) => 
                    
                 formData.dispatch_mode === Constant.MODE_DISPATCH_DRIVER_CHOSEN && 
                 
            
            <ReferenceInput {...rest} label="À un chauffeur choisi" className={classes.autoComplete}
                    filter={{vehicle_type_id: record.vehicle_type_id}}
                    reference="driver" source="driver_id_chosen" perPage={100000}>
                <AutocompleteInput optionText={driverNameField}/>
            </ReferenceInput>
                    
                    
                 }
             </FormDataConsumer>
            </Card>
            
            </div>;

    
};

const EditOrderUnassigned = ({permissions, ...props })  => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Edit title={<Title />} {...props} actions={<EditActions/>} 
            undoable={false}
            successMessage="La réservation a été assignée au chauffeur" >
        <SimpleForm redirect="list" toolbar={<CustomToolbar/>}>
            
            {/* <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/> */}
            <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            <CreatorFullNameField/>
            <div/>
            <TextField source="order_number" label="Nº de commande" formClassName={classes.inlineBlock}/>
            <OrderTypeField source="order_type" label= "Type de commande" formClassName={classes.inlineBlock}/>
            <OrderSubTypeField source="order_sub_type" label= "Covoiturage ?" formClassName={classes.inlineBlock}/>
            <div/>
            <TextField source="luggage_count" label = "Nombre de bagages" formClassName={classes.inlineBlock}/>
            <TextField source="passenger_count" label = "Nombre de passagers" formClassName={classes.inlineBlock}/>
            <div/>


            <TextField source="from_address" label = "Adresse de départ"/>
            <TextField source="to_address" label = "Adresse d'arrivée"/>
            <div/>
                <DistanceField label="Distance" className={classes.inlineBlock}/>
                <DurationField label="Durée" className={classes.inlineBlock}/>
                <div/>

            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandée"/>
            <OrderOptionsField label="Options du trajet"/>
            <ClientFullNameField label="Nom du client"/>
            <div/>
            {/* <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
            <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/>
            <div/> */}
            {/* <ReferenceField label="Client" source="client_id" reference="client" link="show">
                <TextField source="email" />
            </ReferenceField>

            <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="show">
                <TextField source="email" />
            </ReferenceField> */}
            
            <TextField source="total" label="Prix(€)" formClassName={classes.inlineBlock}/>
            <TextField source="commission_amount" label="Commission EDD(€)" formClassName={classes.inlineBlock}/>
            <CommissionCreatorField formClassName={classes.inlineBlock}/>
            <TextField source="driver_amount" label="Commission chauffeur(€)" formClassName={classes.inlineBlock}/>
            <PaymentTypeField label="Mode de paiement"/>

            <ShareClientsField/>
            
            {/* <DriverFullNameEmailField label="Assigné au chauffeur"/>
            <DispatchModeField/> */}
            {/* <br/> */}
            <AssignField/>
            
        </SimpleForm>
    </Edit>
)};

export default EditOrderUnassigned;