import React from 'react';
import { FormDataConsumer, ArrayInput, ImageField, FileInput, FileField, CheckboxGroupInput, SelectInput, SimpleFormIterator, Edit, SelectArrayInput, NumberInput, TextInput, number, TextField, SimpleForm, required, TabbedForm, 
    FormTab, useTranslate, maxLength, minLength, email } from 'react-admin';
    import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { TimeInput } from 'react-admin-date-inputs2';
// import RichTextInput from 'ra-input-rich-text';
import Button from '@material-ui/core/Button';
import { ShowButton, SaveButton, Toolbar, TopToolbar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles,  } from '@material-ui/core/styles';
import {hours, wDays} from '../../util/constant'
import {log, positive, notNegative,} from '../../util/function'
import {validatePostalCode} from '../../util/function';

const EditActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        {/* <ShowButton basePath='/view-my-profile' record={data} /> */}
        {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </TopToolbar>
);

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Enregistrer"
            redirect="/info"
            submitOnEnter={true}
        />
        
    </Toolbar>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('menu.info')}</span>;
};

// const positiveNumber = [required(), number(), positive];
// const notNegativeNumber = [required(), number(), notNegative];

// const validateOverlapPrices = (id, fromWeight, toWeight, prices) => {
//     prices.forEach(function(price, index){
//         console.log('verify price ' + JSON.stringify(price) + 'with ' + id + ',' + fromWeight + ', ' + toWeight);
//         if(price.id !== id){
//             if(fromWeight <= price.from_weight || price.to_weight <= toWeight)
//                 return 'Il y a des superposés entre les prix';
//             if(fromWeight <= price.from_weight || toWeight <= price.to_weight)
//                 return 'Il y a des superposés entre les prix';
//             if(price.from_weight <= fromWeight || toWeight <= price.to_weight)
//                 return 'Il y a des superposés entre les prix';
//             if(price.from_weight <= fromWeight || price.to_weight <= toWeight)
//                 return 'Il y a des superposés entre les prix';
//         }
//     });
//     return [];
// }

// const isValidToWeight = (value, allValues, scopedFormData) => {
//     // console.log('Validate to weight ' + value + ": " + JSON.stringify(allValues.prices));
//     // console.log('Validate to weight ' + value + ": " + JSON.stringify(scopedFormData));
//     // if(value <= scopedFormData.from_weight)
//     // return "'Au poids' doit être supérieur à 'Du poids'";

//     // const prices = allValues.prices;
//     // return validateOverlapPrices(scopedFormData.id, scopedFormData.from_weight, value, prices);
//     return [];
// };

// const isValidFromWeight = (value, allValues, scopedFormData) => {
//     // console.log('Validate from weight ' + value + ": " + JSON.stringify(allValues.prices));
//     // console.log('Validate from weight ' + value + ": " + JSON.stringify(scopedFormData));
//     // if(value > scopedFormData.to_weight)
//     // return "'Du poids' doit être inférieur à 'Au poids'";

//     // const prices = allValues.prices;
//     // return validateOverlapPrices(scopedFormData.id, value, scopedFormData.to_weight, prices);
//     return [];
// };


const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

// const validateToTime = (value, allValues) => {
//     // if (!value) {
//     //     return 'Mot de passe est obligatoire';
//     // }
//     // if (value >= allValues.confirm_password) {
//     //     return 'Mots de passe ne sont pas pareils';
//     // }
//     log('Form all values ' + JSON.stringify(allValues));
//     console.log('Form all values ' + JSON.stringify(allValues));
//     return [];
// };

// const EditConfig = ({ staticContext, ...props }) => {
    const EditConfig = props => {
        const classes = useStyles();
    return (
        <Edit
            actions = {<EditActions/>}
            id='noid'
            resource="config"
            basePath="/info"
            redirect="false"
            title={<Title/>}
            {...props}
        >

        <TabbedForm toolbar={<EditToolbar />}>
            <FormTab label="Coordonnées EDD">
                {/* <span style={{'color':'#2D2787'}}>Remarque: Ces informations sont affichées sur les factures</span>
                <br/> */}
                <TextInput source="company_name" label="Nom" validate={required()}/>
                <TextInput source="company_address_line1" label="Adresse " validate={required()} fullWidth multiline/>
                <TextInput source="company_postal_code" label="Code postal" validate={required()}/>
                <TextInput source="company_city" label="Ville" validate={required()}/>
                <TextInput source="company_siren" label="SIREN" validate={required()}/>
                <TextInput source="company_email" label="Email"/>
                <TextInput source="company_phone" label="Téléphone"/>
                <TextInput source="contact_email" label="Email de contact"/>
                <TextInput source="register_email" label="Email pour l'inscription"/>
                
            </FormTab>

            
        </TabbedForm>
            
          
        </Edit>
    );
};

export default EditConfig;
