import React, {Fragment} from 'react';
import {Pagination, List, Datagrid, TextField, DateField, SelectInput, EditButton, Filter, TextInput, useTranslate, ImageField } from 'react-admin';
import {NoticeSendButton} from '../../control/button/index';
// import {GENDER_MALE, GENDER_FEMALE} from '../../util/constant'
import {FormattedDateField, AvatarField, VerifyField, GenderField, GenderFilterField, LockField, ValidateField} from '../../control/field/index'
const { getCode, getName } = require('country-list');

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
        <NoticeSendButton {...props} toClient={true}/>
    </Fragment>
);


const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.client.list')}</span>;
};

// const AccountField = ({ record = {}, size }) => (
//     <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
//         <AvatarField record={record} size={size} />
//         &nbsp;{record.email}
//     </div>
// );

// var genders = [];
// genders.push({id: GENDER_MALE, name: 'Monsieur'});
// genders.push({id: GENDER_FEMALE, name: "Madame"});

// const GenderFilterField = ({ record }) => {
//     return <span>{record.id === GENDER_MALE ? 'Homme' : 'Femme'}</span>;
// };

// const GenderField = ({ record = {}, label}) => {
//     return <div>
//         <span class>{record.gender == GENDER_MALE? 'Homme' : 'Femme'}</span>
//         {/* <span class>{record[source]}</span> */}
//     </div>;
// }


const ListFilter = ({ permissions, ...props })  => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn resettable/>
        <TextInput label="Mobile" source="mobile" alwaysOn resettable/>
        {/* <SelectInput label="Civilité" source="gender" alwaysOn choices={genders} 
        allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}
        <TextInput label="Nom" source="last_name" resettable/>
        <TextInput label="Prénom" source="first_name" resettable/>
    </Filter>
);

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;

const ListClient = ({permissions, ...props}) => {
    const translate = useTranslate();
    return (
    <List title={<Title/>} {...props} perPage={50} pagination={<ListPagination/>} 
        filters = {<ListFilter permissions={permissions} />} 
        bulkActionButtons={<BulkActionButtons />} exporter={false}>
        <Datagrid rowClick="show">
            <AvatarField source="photo_url" label="" />
            <TextField source="id" label="ID"/>
            {/* <GenderField source="gender" label={translate('civility')}/> */}
            <TextField source="email" label="Email"/>
            <TextField source="last_name" label="Nom"/>
            <TextField source="first_name" label="Prénom"/>
            <TextField source="mobile" label="Mobile"/>
            <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/>
            {/* <VerifyField source="activated" label="Mobile vérifié?"/> */}
            {/* <TextField source="address" label="Adresse postale"/> */}
            <TextField source="country_name" label="Pays"/>
            <TextField source="nationality_name" label="Nationalité"/>
            <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/>
            <ValidateField source="locked" label='Verrouillé'/>
            
            {/* <EditButton/> */}
        </Datagrid>
    </List>
    
)};

export default ListClient;

