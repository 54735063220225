import React from 'react';
import Typography from '@material-ui/core/Typography';
import * as Constant from '../../util/constant';

export const OrderTypeFilterField = ({ record }) => {
    return <span>{record.id === Constant.OrderType.IMMEDIATE ? 'Immédiate' : "Réservation à l'avance"}</span>;
};

const OrderTypeField = ({ record = {}, label, customVariant="body1"}) => {
    return <div>
         <Typography variant={customVariant}>
        {record.order_type === Constant.OrderType.IMMEDIATE? 'Immédiate' : "Réservation à l'avance"}
        </Typography>
    </div>;
}

OrderTypeField.defaultProps = {
    addLabel: true,
};

export const OrderSubTypeFilterField = ({ record }) => {
    return <span>{record.id === Constant.OrderSubType.NO_SHARING ? 'Solo' : "Covoiturage"}</span>;
};

export const OrderSubTypeField = ({ record = {}, label}) => {
    return <div>
         <Typography variant="body1">
        {record.order_sub_type === Constant.OrderSubType.NO_SHARING? 'Non' : "Oui"}
        </Typography>
    </div>;
}

OrderSubTypeField.defaultProps = {
    addLabel: true,
};


export default OrderTypeField;
