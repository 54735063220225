import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form'
import { change, submit, isSubmitting } from 'redux-form';
import {
    crudUpdate,
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    Button,
    SaveButton,
    SimpleForm,
    TextInput,
    FileInput, ImageField,

    CREATE,
    UPDATE,
    FileField,
    Loading,
    REDUX_FORM_NAME
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';
import { push } from 'react-router-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import TextInput from 'ra-input-rich-text';
import ActionSend from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import IconCancel from '@material-ui/icons/Cancel';
import axios from 'axios';
import {BACKEND_UMS_URL} from '../../util/variant'
import {httpClient} from '../../util/function'
import StarRatings from 'react-star-ratings';

// import jsonDataProvider from '../../provider/json-data-provider';
// const dataProvider = jsonDataProvider(BACKEND_UMS_URL, httpClient);

class RatingChangeButton extends Component {
    state = {
        showDialog: false,
        showLoading: false,
       
    }

    state = {
        error: false,
        showDialog: false,
        rating: 0,
    };

    changeRating = (newRating, name) => {
        this.setState({
            rating: newRating,
        });
    }

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        console.log('Change avatar props: ' + JSON.stringify(this.props));
        const { submit, record } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        // alert('Click');
        submit(record.id);
    };

    handleSubmit = values => {
        const { record, crudUpdate, basePath } = this.props;
        // console.log('Reject doc button props: ' + this.props);
        // Dispatch an action letting react-admin know a API call is ongoing
        // fetchStart();

        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        // values['id'] = record.id;
        // values['status'] = DataStatus.REJECTED;

        // console.log('Avatar change props: ' + basePath + '/show'); 
        console.log('Change rating : ' + this.state.rating);
        if(this.state.rating > 0){
            crudUpdate('order_rating', record.id, {rating_on_driver: this.state.rating}, basePath, 'show', true);
        }
        
        
        // crudUpdate('profile_photo', record.id, {binary_photo: values['binary_photo']}, basePath + "/" + record.id + "/show");
        
        
        // // , reason: values['reason']
        // dataProvider(UPDATE, 'doc_status', { id: record.id, data: {id: record.id, status: DocStatus.REJECTED, reason: values['reason']}})
        //     .then(({ data }) => {
        //         this.setState({ showDialog: false });
        //     })
        //     .catch(error => {
        //         showNotification(error.message, 'error');
        //     })
        //     .finally(() => {
        //         // Dispatch an action letting react-admin know a API call has ended
        //         fetchEnd();
        //     });

        this.setState({ showDialog: false });

        // // const { fetchStart, fetchEnd, showNotification, record } = this.props;
        // // console.log('Reject doc button props: ' + this.props);
        // // Dispatch an action letting react-admin know a API call is ongoing
        // // fetchStart();
        // const { basePath, crudUpdateMany, resource, record } = this.props;

        // // As we want to know when the new post has been created in order to close the modal, we use the
        // // dataProvider directly
        // // values['id'] = record.id;
        // // values['status'] = DocStatus.REJECTED;

        // console.log('Send notice button values: ' + JSON.stringify(values)); 
        // // , reason: values['reason']
        // // crudCreate('notice', {}, basePath, 'show', true);

        // var data = {data: { id: record.id, photo_binary: values['photo']}};
            
        // dataProvider(UPDATE, 'profile_photo', data)
        // // dataProvider(CREATE, 'notice', {data: { recipients: selectedIds, values}})
        //     .then(({ data }) => {
        //         this.setState({ showDialog: false });
        //     })
        //     .catch(error => {
        //         showNotification(error.message, 'error');
        //     })
        //     .finally(() => {
        //         // Dispatch an action letting react-admin know a API call has ended
        //         fetchEnd();
        //     });

        // this.setState({ showDialog: false });
    };

    componentDidMount(){
        
    }

    render() {
        const { showDialog, showLoading } = this.state;
        const { isSubmitting, record } = this.props;
        
       
        const onSubmit = async values => {
            // window.alert(JSON.stringify(values, 0, 2))
            this.handleSubmit(values);
        }

        // var id = record.id;
        let submit;

        return (
            <Fragment>
                <Button onClick={this.handleClick} label='Changer'>
                    <EditIcon />
                </Button>
                { this.state.isLoading && 
                    <Loading loadingPrimary="" loadingSecondary="" />
                    }
                <Dialog
                    fullWidth={false}
                    maxWidth="600xs"
                    // style={{'width':'500px', 'height': '400px'}}
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Changer la notation du chauffeur"
                >
                    <DialogTitle>Changer la notation du chauffeur</DialogTitle>
                    <DialogContent>
                    <Form
                    style={{'width':'500px', 'height': '400px'}}
                            toolbar={null}
                            onSubmit={onSubmit}
                            render={({ handleSubmit, form, submitting, pristine, values }) => {submit = handleSubmit
                            return (
                                <form id="notice-send-form" onSubmit={handleSubmit}>
                                <div>
                                <br/>
                                <br/>
                                <StarRatings
                                    rating={this.state.rating}
                                    // rating={4}
                                    changeRating={this.changeRating}
                                    starRatedColor="#00ad8f"
                                    starHoverColor="#00ad8f"
                                    numberOfStars={5}
                                    name='Notation au chauffeur'
                                    starDimension="32px"
                                        starSpacing="5px"/>

                                </div>
                                <br/><br/>
                                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                                </form>
                            )
                            }}
                    >

                    </Form>

                        
                    </DialogContent>
                    <DialogActions>
                        {/* <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        /> */}
                       

                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel color="secondary"/>
                        </Button>
                        <Button label="Sauvegarder" onClick={() =>
                                // { cancelable: true } required for Firefox
                                // https://github.com/facebook/react/issues/12639#issuecomment-382519193
                                document
                                .getElementById('notice-send-form')
                                .dispatchEvent(new Event('submit', { cancelable: true }))
                            } saving={isSubmitting}>
                            <ActionSend />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('notice-send-form')(state)
});

RatingChangeButton.propTypes = {
    label: PropTypes.string,
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};


const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

// export default connect(mapStateToProps, mapDispatchToProps)(
//     AvatarButton
// );

export default connect(undefined, { crudUpdate })(RatingChangeButton);