// export const BACKEND_URL = 'http://35.204.157.144/rest/v1/index.php/web';

import {LocationType} from '../util/constant'
import { createMuiTheme } from '@material-ui/core/styles';
// import error from '@material-ui/core/colors/red';
import {ACTIVITY_TYPE_TAXI, ACTIVITY_TYPE_VTC, ACTIVITY_TYPE_TPMR} from './constant'

export const ROOT_URL = 'https://rest.eddapps.fr';
export const ROOT_UMS_URL = ROOT_URL + '/ums';
export const ROOT_OMS_URL = ROOT_URL + '/oms';

export const UNAUTH_UMS_URL = ROOT_UMS_URL + '/rest/v1/index.php/web';
export const BACKEND_UMS_URL = UNAUTH_UMS_URL + '/auth';

export const UNAUTH_OMS_URL = ROOT_OMS_URL + '/rest/v1/web';
export const BACKEND_OMS_URL = UNAUTH_OMS_URL + '/auth';

export const BACKEND_API_KEY = 'edwb2d7f4f23-2be5-4c62-96be-5170ead77d9d';
// export const GOOGLE_API_KEY = 'AIzaSyAqKeAOiuZ3jB_2vJE5CSmHcKc67OOKVOY';
export const GOOGLE_API_KEY = 'AIzaSyC8kmrvd-oXExcqGb-2ZhRgJ6CxNo-Q_fA';

export const PUSHER_API_KEY = 'd2fa92002117ba9db6a4';
export const PUSHER_CLUSTER = 'eu';
export const PUSHER_CHANNEL_USER = 'user_channel';
export const PUSHER_CHANNEL_ORDER = 'order_channel';
export const PUSHER_CHANNEL_LOCATION = 'location_channel';
export const EVENT_ONLINE_DRIVERS_CHANGED = 'online_drivers_changed';
export const EVENT_DRIVERS_LOCATION_CHANGED = 'drivers_location_changed';

export const EVENT_ORDER_NEW = 'order_new';


export const BACKEND_FILE_URL = ROOT_URL;

export const locationTypes = [
        {id: LocationType.AIRPORT, name: 'Aéroport'},
        {id: LocationType.STATION, name: 'Gare'},
        {id: LocationType.PLACE, name: 'Endroit'},
        {id: LocationType.CLUBS, name: 'Club'},
        {id: LocationType.HOTEL, name: 'Hôtel'},
        {id: LocationType.RESTAURANT, name: 'Restaurant'},
        {id: LocationType.HOSPITAL, name: 'Hôpital'},
        {id: LocationType.BEACH, name: 'Plage'},
        {id: LocationType.TOURISTIC_SITE, name: 'Site touristique'},
        {id: LocationType.HISTORICAL_SITE, name: 'Site historique'},
        {id: LocationType.SCHOOL, name: 'École'},
        {id: LocationType.MALL, name: 'Centre commercial'},
];


export const locationDict = {
    [LocationType.AIRPORT]:{id: LocationType.AIRPORT, name: 'Aéroport'},
    [LocationType.STATION]:{id: LocationType.STATION, name: 'Gare'},
    [LocationType.PLACE]:{id: LocationType.PLACE, name: 'Endroit'},
    [LocationType.CLUBS]:{id: LocationType.CLUBS, name: 'Club'},
    [LocationType.HOTEL]:{id: LocationType.HOTEL, name: 'Hôtel'},
    [LocationType.RESTAURANT]:{id: LocationType.RESTAURANT, name: 'Restaurant'},
    [LocationType.HOSPITAL]:{id: LocationType.HOSPITAL, name: 'Hôpital'},
    [LocationType.BEACH]:{id: LocationType.BEACH, name: 'Plage'},
    [LocationType.TOURISTIC_SITE]:{id: LocationType.TOURISTIC_SITE, name: 'Site touristique'},
    [LocationType.HISTORICAL_SITE]:{id: LocationType.HISTORICAL_SITE, name: 'Site historique'},
    [LocationType.SCHOOL]:{id: LocationType.SCHOOL, name: 'École'},
    [LocationType.MALL]:{id: LocationType.MALL, name: 'Centre commercial'},
};


export const activityTypes = [{id: ACTIVITY_TYPE_TAXI, name: "Taxi"},
{id: ACTIVITY_TYPE_VTC, name: "VTC"}, {id: ACTIVITY_TYPE_TPMR, name: "TPMR"}];
// activityTypes.push({id: ACTIVITY_TYPE_TAXI, name: "Taxi"});
// activityTypes.push({id: ACTIVITY_TYPE_VTC, name: "VTC"});
// activityTypes.push({id: ACTIVITY_TYPE_TPMR, name: "TPMR"});

export const theme = createMuiTheme({
    sidebar:{
      width: 300,
    },
    palette: {
      secondary: {
        // main: '#909090',
        main: '#00ad8f',
      },
      primary: {
        // main: '#FCC814',
        main: '#2e3142',
      },
      error: {main: '#ff0000'},
      // contrastThreshold: 3,
      // tonalOffset: 0.2,
  },
    typography: {
        // Use the system font instead of the default Roboto font.
        // fontFamily: [
        //     '-apple-system',
        //     'BlinkMacSystemFont',
        //     '"Segoe UI"',
        //     'Arial',
        //     'sans-serif',
        // ].join(','),
        fontFamily: [
          'Inter',
          'Comfortaa',
      ].join(','),
    },
    overrides: {
        // MuiButton: { // override the styles of all instances of this component
        //     root: { // Name of the rule
        //         color: 'white', // Some CSS
        //     },
        // },
        MuiFilledInput: {
          root: {
            borderRadius: 0,
            backgroundColor: "#fff",
          },
        },
        
        // MuiInputLabel:{
        //   root:{
        //     color: '#00ad8f',
        //   },
          
        // },

        // MuiFormLabel:{
        //   root:{
        //     color: '#2e3142',
        //     fontWeight:'600',
        //     fontSize:'18px'
        //   }
          
        // },
        
        MuiTableCell: {
          head:{
            fontWeight: 600,
            background:'#8ef',
          }

        }
    },
  });
  
// export const locationTypes = [
//     { name: 'orderNumber', title: 'Order', width: '10%',  type: 'string' },
//     { name: 'orderType', title: 'Type', width: '10%',  type: 'string' }
//   ];


// var locationTypes = [];
// locationTypes.push({id: LocationType.AIRPORT, name: 'Aéroport'});
// locationTypes.push({id: LocationType.STATION, name: 'Gare'});
// locationTypes.push({id: LocationType.PLACE, name: 'Endroit'});
// export lo
