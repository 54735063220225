
import React from 'react';
import { FormDataConsumer, TextInput, Create, 
    FileInput, ImageField, SimpleForm, PasswordInput, 
    required, email,
    useTranslate} from 'react-admin';
// import { DateInput } from 'react-admin-date-inputs2';
import { DateInput } from 'react-admin-date-inputs2';
import DateFnsUtils from '@date-io/date-fns';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";

DateFnsUtils.prototype.getStartOfMonth = DateFnsUtils.prototype.startOfMonth

const validateConfirmPassword = (value, allValues) => {
    if (value !== allValues.password) {
        return 'Mots de passe ne sont pas similaires';
    }
    return [];
};

// const validateMobileNumber = (value, allValues) => {
//     if (!value.startsWith('33') || value.length !== 12) {
//         return "Doit être 12 chiffres sous forme '330xxxxxxxxx'";
//     }
//     return [];
// };
const validateMobileNumber = (value, allValues) => {
    if (!value.startsWith('330') || value.length !== 12) {
        return "Doit être 12 chiffres sous forme '330xxxxxxxxx'";
    }
    // if (value.length !== 10) {
    //     return "Doit être 10 chiffres";
    // }
    return [];
};

const validatePassword = [required(), validateConfirmPassword];
const validateEmail = [required(), email()];
const validateMobile = [required(), validateMobileNumber];

// import Strings from '../res/strings';
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.create')}</span>;
};

const CreateDriver = props => (
    <Create title={<Title/>} {...props}>
        <SimpleForm redirect="list">
            <TextInput source="email" label="Email" validate={validateEmail}/>
            <TextInput source="mobile" label="Mobile" validate={validateMobile}/>
            <TextInput source="last_name" label="Nom" validate={required()}/>
            <TextInput source="first_name" label="Prénom" validate={required()}/>
            <PasswordInput source="password" label="Mot de passe" validate={required()}/>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <PasswordInput {...rest} source="password_confirm" label="Mot de passe confirmé" validate={validatePassword}/>
                     
                }
            </FormDataConsumer>
            
            
            <FileInput source="photo" label="Photo" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="id_card" label="Carte d'identité" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="vtc_card" label="Carte VTC" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="driving_license" label="Permis de conduire" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="vtc_register_cert" label="Certificat d'inscription au registre VTC" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            {/* <DateInput source="birth_day" label="Date de naissance"
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}
            {/* <DateInput source="birth_day" label="Date de naissance"
            autoComplete="" options={{ format: 'dd/MM/yyyy' }} 
                 providerOptions={{ utils: DateFnsUtils, locale: frLocale }}
            /> */}
            
        </SimpleForm>
    </Create>
);

export default CreateDriver;

{/* options={{ format: 'dd/MM/yyyy' }  */}