
import React from 'react';
import { number, email, FileInput, ImageField, PasswordInput, TextField, FormTab, TabbedForm, SelectInput, TextInput, Edit, SimpleForm, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import Typography from '@material-ui/core/Typography';

import {GENDER_MALE, GENDER_FEMALE, USER_TYPE_AUTO_ENTERPRENER, USER_TYPE_COMPANY_AND_DRIVER} from '../../util/constant';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({permissions, ...props}) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <DeleteButton label="ra.action.delete" redirect='list' {...props}/> */}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: {
        // paddingLeft: 50,
        color: '#F4004E',
    },

    noColorLabel: {
        paddingLeft: 50,  
    },

    colorValue: {
        color: '#F4004E',
        paddingLeft:5
    },

    supplementText: {
        color: '#F4004E',
    },

    noHeader:{
        display:'none',
        // height:'1px'
    }
    
});

const styles = {
    flex: { display: 'flex', },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
    button:{ flex: 1, marginTop: '1em'},
};


const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.edit')}: {record ? `${record.email}` : ''}</span>;
};

var genders = [];
genders.push({id: GENDER_MALE, name: 'Monsieur'});
genders.push({id: GENDER_FEMALE, name: "Madame"});

var driverTypes = [];
driverTypes.push({id: USER_TYPE_AUTO_ENTERPRENER, name: "Auto-entreprise"});
driverTypes.push({id: USER_TYPE_COMPANY_AND_DRIVER, name: "Autre"});

const validatePostalCodeRange = (value, allValues) => {
  if (value < 1000 || value > 99000) {
      return 'Doit être en [01000, 99000]';
  }
  return [];
};

const validateConfirmPassword = (value, allValues) => {
    if (value !== allValues.password) {
        return 'Mots de passe ne sont pas similaires';
    }
    return [];
};

const validateMobileNumber = (value, allValues) => {
    if (!value.startsWith('330') || value.length !== 12) {
        return "Doit être 12 chiffres sous forme '330xxxxxxxxx'";
    }
    // if (value.length !== 10) {
    //     return "Doit être 10 chiffres";
    // }
    return [];
};

const validatePassword = [required(), validateConfirmPassword];
const validateEmail = [required(), email()];
const validateMobile = [required(), validateMobileNumber];


const validatePostalCode = [required(), number(), validatePostalCodeRange];

const EditDriver = ({permissions, ...props })  => {
    const classes = useStyles();
    return(
    <Edit title={<Title />} {...props} actions={<EditActions/>}>
         <SimpleForm redirect="list" 
                toolbar={<CustomToolbar/>}>
                    <TextInput source="email" label="Email" disabled/>
            <TextInput source="mobile" label="Mobile" validate={validateMobile}/>
            <TextInput source="last_name" label="Nom" validate={required()}/>
            <TextInput source="first_name" label="Prénom" validate={required()}/>
            {/* <PasswordInput source="password" label="Mot de passe" validate={required()}/>
            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    <PasswordInput {...rest} source="password_confirm" label="Mot de passe confirmé" validate={validatePassword}/>
                     
                }
            </FormDataConsumer> */}
            
            
            <FileInput source="photo" label="Photo" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="id_card" label="Carte d'identité" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="vtc_card" label="Carte VTC" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="driving_license" label="Permis de conduire" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      

            <FileInput source="vtc_register_cert" label="Certificat d'inscription au registre VTC" multiple={false} accept="image/*">
                <ImageField source="src" title="title" label="Nom du fichier" target="_blank"/>
            </FileInput>      
                        
                        
                    

        </SimpleForm>
    </Edit>
);
};

export default EditDriver;