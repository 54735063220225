
import React, {Link} from 'react';
import { Pagination, DateField, SimpleShowLayout,TextField, Show, useTranslate, ImageField, TabbedShowLayout, Tab,
    ReferenceManyField, Datagrid} from 'react-admin';
import { EditButton, ListButton } from 'react-admin';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {OrderStatusField, FormattedDateField, AvatarField, FullNameField, GenderField, GenderFilterField, LockField, ValidateField} from '../../control/field/index'
// import {
//     Icon_Visa,
//     Icon_MasterCard
//   } from 'material-ui-credit-card-icons';
  
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});


const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const changePassword = () => {
    // window.location.href = '/#/change-password';
}

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath}/>
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.client.show')}: {record ? `${record.email}` : ''}</span>;
};

const CardNumberField = ({record, label}) => {
    // const classes = useStyles();
    // const data = JSON.parse(record.data);
    console.log('Card ' + JSON.stringify(record));
    console.log('Car data ' + JSON.stringify(record.data));
    return <div><span>{'**** **** **** ' + record.data.last4}</span></div>    
    // return <div></div>
}

const DriverDataField = ({ record = {}, source, label}) => {
    const driver = record.driver;
    if(typeof(driver) !== 'undefined' && driver !== null && driver !== ''){
        const driverObj = JSON.parse(driver); 
        return <Typography variant="body2">{driverObj[source]}</Typography>
    }
    
    return <span></span>;
}

DriverDataField.defaultProps = {
    addLabel: true,
};

const ExpiryField = ({record, label}) => {
    // const classes = useStyles();
    // const data = JSON.parse(record.data);
//    console.log('Card ' + JSON.stringify(record));
  //  console.log('Car data ' + JSON.stringify(record.data));
    if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined')
        return <div><span>{record.data.expiry_month + '/' + record.data.expiry_year}</span></div>    
    return <div></div>
}

// const CardTypeField = ({record, label}) => {
//     if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined'){
//         if(record.data.card_type == "Visa")
//             return <Icon_Visa/>
        
//         if(record.data.card_type == "MasterCard")
//             return <Icon_MasterCard/>

//         return <span>{record.data.card_type}</span>
//     }
//     return <span>"Inconnu"</span>
// }

// const userId = localStorage.getItem('user_id');
const role = localStorage.getItem('role');

const ShowClient = ({staticContext, ...props}) => {
    const translate = useTranslate();
    const classes = useStyles();
    return(
    <Show title={<Title />} {...props} actions={<ShowActions />}>
            <TabbedShowLayout redirect="list">
            <Tab label="Profil">
                <AvatarField source="photo_url" label="" size='160'/>
                {/* <TextField source="user_number" label="ID"/> */}
                <TextField source="id" label="ID"/>
                <GenderField label={translate('civility')}/>
                <div/>
                <TextField source="last_name" label="Nom" className={classes.inlineBlock}/>
                <TextField source="first_name" label="Prénom"className={classes.inlineBlock}/>
                <br/>
                <div/>
                <TextField source="mobile" label = "Mobile" className={classes.inlineBlock}/>
                <TextField source="email" label = "Email" className={classes.inlineBlock}/>
                <br/>
                <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR" className={classes.inlineBlock}/>
                <FormattedDateField source="delete_time" label="Date de suppression"  locales="fr-FR" className={classes.inlineBlock}/>
                <br/>
                <Divider/>
                <br/>
                {/* <Typography variant="body2">État</Typography> */}
                <ValidateField source="activated" label='Activé' className={classes.inlineBlock}/>
                <ValidateField source="validated" label='Validé' className={classes.inlineBlock}/>
                <ValidateField source="locked" label='Verrouillé' className={classes.inlineBlock}/>
                <ValidateField source="deleted" label='Supprimé' className={classes.inlineBlock}/>
            </Tab>
            <Tab label="Cartes de paiement">
                <ReferenceManyField reference="card" target="user_id" addLabel={false}>
                    <Datagrid>
                        <CardNumberField label='Numéro de la carte'/>  
                        <ExpiryField label="Expiration"/>
                        <TextField source="data.card_type" label="Type"/>
                        <ValidateField source="is_default" label="Par défaut"/>      
                    </Datagrid>
                </ReferenceManyField>
                
            </Tab>
            
            <Tab label="Commandes">
            <ReferenceManyField pagination={<Pagination />} reference="order_client" target="client_id" addLabel={false}>
                        <Datagrid rowClick="show">
                        <TextField source="create_time" label="Date"/>
                        <TextField source="order_number" label="Nº de commande"/>
                        <DriverDataField source="last_name" label="Nom du chauffeur"/>
                        <DriverDataField source="first_name" label="Prénom du chauffeur"/>
                        <TextField source="from_address" label="Adresse de départ"/>
                        <TextField source="to_address" label="Adresse d'arrivée"/>
                        <TextField source="total" label="Prix(€)"/>
                        <OrderStatusField source="status" label = "État"/>
                        <TextField source="rating_on_client" label="Note attribué par le chauffeur"/>
                        </Datagrid>
                    </ReferenceManyField>
            </Tab>
            </TabbedShowLayout>
    </Show>);
}


export default ShowClient;