
import React, {Link} from 'react';
import { SimpleShowLayout,Datagrid, ReferenceManyField, Labeled, TabbedShowLayout, Tab, TextField, ReferenceField, Show, useTranslate } from 'react-admin';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// const Title = ({ record }) => {
//     return <span>Mon profil</span>;
// };
import {OrderTypeField, OrderStatusField, FormattedDateField, OrderSubTypeField, OrderOptionsField,
    PaymentTypeField,
    DurationField, DistanceField,} from '../../control/field/index'
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {locationDict} from '../../util/variant'
import {PdfViewButton, DownloadButton, RatingChangeButton} from '../../control/button/index'
import StarRatings from 'react-star-ratings';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorPrimary: {
        color:'#F4004E',
    },
});

const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.order_finished.show')}:#{record ? `${record.order_number}` : ''}</span>;
    
};

const TypeTextField = ({ source, record = {}, label}) => {
    const locationType = locationDict[record.type];
    return <span class="MuiTypography-root MuiTypography-body2">{typeof(locationType) !== 'undefined' ? locationType.name : record.type}</span>;
}
    
TypeTextField.defaultProps = {
    addLabel: true,
};

const RatingDriverChangeButton  =  ({ source, record = {}, basePath, label}) => {
    return <RatingChangeButton record={record} source={source} basePath={basePath}/>;
}

const DownloadFileButton  =  ({ source, record = {}, label}) => {
    return <DownloadButton fileUrl={record.file_url} fileName={record.file_name}/>;
    // return <DownloadButton onClick={(e) => alert("Fichier facture n'est pas encore disponible")} fileName={record.file_name}/>;
}

DownloadFileButton.defaultProps = {
    addLabel: true,
};

const ClientFullNameField = ({ record }) => <span>{record.c_first_name} {record.c_last_name}</span>;
ClientFullNameField.defaultProps = {
    addLabel: true,
};
const DriverFullNameField = ({ record }) => <span>{record.d_first_name} {record.d_last_name}</span>;
DriverFullNameField.defaultProps = {
    addLabel: true,
};
const CreatorFullNameField = ({ record }) => {
    if(typeof(record) === 'undefined' || record === 'undefined') return null;
    if(record.creator_id === "undefined" || record.creator_id === null || record.creator_id === '') return null;
    return <div>
    <Labeled label="Créée par">
    <span style={{'color':'red'}}>{record.cr_first_name} {record.cr_last_name}</span>
    </Labeled>
    </div>;
}
CreatorFullNameField.defaultProps = {
    addLabel: false,
};

const CommissionCreatorField = ({ record }) => {
    if(typeof(record) === 'undefined' || record === 'undefined') return null;
    if(record.creator_id === "undefined" || record.creator_id === null || record.creator_id === '') return null;
    return <div>
    <Labeled label="Commission créateur chauffeur(€)">
    <Typography variant="body1">{record.commission_creator}</Typography>
    </Labeled>
    </div>;
}
CommissionCreatorField.defaultProps = {
    addLabel: false,
};

const RatingClientField  =  ({ source, record = {}, label}) => {
    // return <DownloadButton fileUrl={record.file_url} fileName={record.file_name}/>;
    return <StarRatings
    rating={Number(record.rating_on_client)}
    // rating={4}
    starRatedColor="#FCC814"
    numberOfStars={5}
    name='Notation au client'
    starDimension="32px"
        starSpacing="5px"

  />
}

RatingClientField.defaultProps = {
    addLabel: true,
};


const RatingDriverField  =  ({ source, record = {}, label}) => {
    // return <DownloadButton fileUrl={record.file_url} fileName={record.file_name}/>;
    return <StarRatings
    rating={Number(record.rating_on_driver)}
    // rating={4}
    starRatedColor="#FCC814"
    numberOfStars={5}
    starDimension="32px"
        starSpacing="5px"
    name='Notation au chauffeur'
  />
}

RatingDriverField.defaultProps = {
    addLabel: true,
};

const ShareClientsField = ({record = {}, basePath}) => {
    if(typeof(record) === 'undefined' || record === 'undefined') return null;
    if(record.order_sub_type !== "8") return null;
    return (<div>
     {/* <Labeled label="Passagers de covoiturage"> */}
     <Typography variant="body2" color="secondary">Passagers de covoiturage</Typography>
     {/* filter={{going_order_id: record.id}}  */}
    <ReferenceManyField perPage={20} reference="order" basePath={basePath} filter={{going_order_id: record.id}} addLabel={true} label="Passagers de covoiturage">
        <Datagrid>
            <ClientFullNameField label="Nom du client"/>
            <TextField source="from_address" label="Adresse de départ"/>
            <TextField source="to_address" label="Adresse d'arrivée"/>
            <TextField source="total" label="Prix(€)"/>
            
            {/* <FormattedDateField source="at_pick_time" format="format_date_time" label="Sur place"/> */}
            {/* <FormattedDateField source="onboard_time" format="format_date_time" label="À bord"/> */}
            <FormattedDateField source="start_time" format="format_date_time" label="Début"/>
            <FormattedDateField source="end_time" format="format_date_time" label="Arrivée"/>
        </Datagrid>
    </ReferenceManyField>
    {/* </Labeled> */}
    </div>
    );
};

ShareClientsField.defaultProps = {
    addLabel: false,
};

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');

const ShowOrderClient = ({staticContext, ...props}) => {
    const classes = useStyles();
    return(
    <Show title={<Title />} {...props} actions={<ShowActions />}>
             <TabbedShowLayout redirect="list">
            <Tab label="Infos">
                <OrderStatusField source="status" label = "État"/>
                <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
                <CreatorFullNameField/>

                <div/>
                <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock}/>
                <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
                <OrderSubTypeField source="order_sub_type" label= "Covoiturage" className={classes.inlineBlock}/>
                <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type">
                <TextField source="name" />
            </ReferenceField>
                <div/>
                
            <TextField source="luggage_count" label = "Nombre de bagages" className={classes.inlineBlock}/>
            <TextField source="passenger_count" label = "Nombre de passagers" className={classes.inlineBlock}/>
            <OrderOptionsField label="Options"/>
            <div/>
                {/* <div/>
                <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
                <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
                <div/> */}
                {/* <ClientFullNameField label="Client"/> */}
                <TextField source="from_address" label = "Adresse de départ"/>
                <TextField source="to_address" label = "Adresse d'arrivée"/>
                
                    
                <div/>
                <DistanceField label="Distance" className={classes.inlineBlock}/>
                <DurationField label="Durée" className={classes.inlineBlock}/>
                <div/>

                <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandée"/>
                {/* <div/>
                <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
                <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/>
                <div/> */}
                {/* <DriverFullNameField label="Chauffeur"/> */}
                <TextField source="original_total" label="Prix(€)" className={classes.inlineBlock}/>
                <TextField source="commission_amount" label="Commission EDD(€)" className={classes.inlineBlock}/>
                
                <CommissionCreatorField/>
                
                <TextField source="driver_amount" label="Commission chauffeur(€)"/>
                <PaymentTypeField label="Mode de paiement"/>
                {/* <TextField source="pt_name" label="Mode de paiement"/> */}
                {/* <div/> */}
                <br/>
                <ShareClientsField/>
                {/* <Divider/> */}
                <br/>
                <Typography variant="body2" color="secondary">Déroulement</Typography>
                <div/>
                <FormattedDateField source="start_time" format="format_date_time" label="Heure de début" className={classes.inlineBlock}/>
                {/* <FormattedDateField source="at_pick_time" format="format_date_time" label="Chauffeur sur place" className={classes.inlineBlock}/> */}
                {/* <FormattedDateField source="onboard_time" format="format_date_time" label="Passager à bord" className={classes.inlineBlock}/> */}
                {/* <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/> */}
                <FormattedDateField source="end_time" format="format_date_time" label="Heure de fin" className={classes.inlineBlock}/>
                <FormattedDateField source="cancel_time" format="format_date_time" label="Heure d'annulation" className={classes.inlineBlock}/>

                {/* <Typography variant="body2" color="secondary">Covoiturage</Typography> */}
              
                {/* <ReferenceManyField pagination={<Pagination />} reference="order" target="going_order_id" addLabel={false}>
                    
                    <Datagrid>
                    
                        <TextField label="Adresse de départ" source="from_address"/>
                        <TextField label="Adresse d'arrivée" source="to_address"/>
                        <TextField label="Adresse d'arrivée" source="to_address"/>
                        <ClientFullNameField label="Client"/>
                        <TextField source="total" label="Prix(€)"/>
                        
                    </Datagrid>
                </ReferenceManyField> */}
            </Tab>
            <Tab label="Notations">
                <TextField source="comment_on_client" label="Commentaire au client" className={classes.inlineBlock}/>
                <RatingClientField label="Notation au client"/>

                <br/>
                <TextField source="comment_on_driver" label="Commentaire au chauffeur" className={classes.inlineBlock}/>
                <RatingDriverField label="Notation au chauffeur"/>
                <br/>
                <RatingDriverChangeButton/>
                <br/>
                </Tab>

            <Tab label="Factures">
            <ReferenceManyField reference="invoice" target="order_id" addLabel={false}>
                    {/* <Datagrid expandHeader={<div/>}> */}
                    <Datagrid>
                        {/* <DocNameField label="Liste de documents d'activité du chauffeur"/> */}
                        <TextField label="Nom" source="invoice_name"/>
                        <TextField label="Numéro de facture" source="invoice_number"/>
                        <TextField label="Date de facture" source="invoice_date"/>
                        {/* <OpenFileButton/> */}
                        <DownloadFileButton/>
                    </Datagrid>
                </ReferenceManyField>
            
            </Tab>
             </TabbedShowLayout>
        {/* <SimpleShowLayout>
        <OrderStatusField source="status" label = "État" className={classes.colorPrimary}/>
             <FormattedDateField source="create_time" format="format_date_time" label="Date/heure"/>
            <div/>
            <TextField source="order_number" label="Nº de commande" className={classes.inlineBlock}/>
            <OrderTypeField source="order_type" label= "Type de commande" className={classes.inlineBlock}/>
            <div/>
            <TextField source="c_last_name" label="Nom du client" className={classes.inlineBlock}/>
            <TextField source="c_first_name" label="Prénom du client" className={classes.inlineBlock}/>
            <div/>
            <TextField source="from_address" label = "Adresse de départ"/>
            <TextField source="to_address" label = "Adresse d'arrivée"/>
            <FormattedDateField source="pick_time" format="format_date_time" label="Prise en charge demandé"/>
            <div/>
            <TextField source="d_last_name" label="Nom du chauffeur" className={classes.inlineBlock}/>
            <TextField source="d_first_name" label="Prénom du chauffeur" className={classes.inlineBlock}/>
            <div/>
            <TextField source="total" label="Prix(€)"/>
            <TextField source="commission_amount" label="Commission Veebya(€)"/>
            <TextField source="driver_amount" label="Commission chauffeur(€)"/>
            
            
            <div/>
            <Divider/>
            <br/>
            <Typography variant="body2">Déroulement</Typography>
            <div/>
            <FormattedDateField source="start_time" format="format_date_time" label="Heure de début"/>
            <div/>
            <FormattedDateField source="at_pick_time" format="format_date_time" label="Chauffeur sur place" className={classes.inlineBlock}/>
            <FormattedDateField source="onboard_time" format="format_date_time" label="Passager à bord" className={classes.inlineBlock}/>
            <FormattedDateField source="end_time" format="format_date_time" label="Heure d'arrivée" className={classes.inlineBlock}/>
            <div/>
            <FormattedDateField source="end_time" format="format_date_time" label="Heure de fin"/>
            <FormattedDateField source="cancel_time" format="format_date_time" label="Heure d'annulation"/>
        </SimpleShowLayout> */}
    </Show>
)};


export default ShowOrderClient;