import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form'
import { change, submit, isSubmitting } from 'redux-form';
import {fetchEnd, fetchStart, required, showNotification, Button,
    SaveButton, SimpleForm, TextInput, crudUpdate, UPDATE,
    FileField, REDUX_FORM_NAME
} from 'react-admin';
import { AutocompleteInput, ReferenceInput, SelectInput } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import AssignIcon from '@material-ui/icons/TouchApp';
import ActionSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import RichTextInput from 'ra-input-rich-text';

import jsonDataProvider from '../../provider/json-data-provider';
import {httpClient} from '../../util/function'
import {BACKEND_UMS_URL} from '../../util/variant'
import {VehicleStatus} from '../../util/constant'

import { log } from 'util';
const dataProvider = jsonDataProvider(BACKEND_UMS_URL, httpClient);
const styles = {
    suggestionsContainerOpen: {
      zIndex: 2100,
      'z-index': 2100,
    },
  };


const DriverOptionRenderer = choice => (
    <span>
        {choice.last_name} {choice.first_name}
    </span>
);

const DriverInputText = choice => `${choice.last_name} ${choice.last_name}`;

const onSubmit = async values => {
    // window.alert(JSON.stringify(values, 0, 2))
    this.handleSubmit(values);
}


class AssignButton extends Component {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        console.log('Reject doc button props: ' + JSON.stringify(this.props));
        const { submit, record } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        // alert('Click');
        submit(record.id);
    };

    handleSubmit = values => {
        // const { fetchStart, fetchEnd, showNotification, record } = this.props;
        // console.log('Reject doc button props: ' + this.props);
        // Dispatch an action letting react-admin know a API call is ongoing
        // fetchStart();
        const { record, crudUpdate, basePath } = this.props;

        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly
        // values['id'] = record.id;
        // values['status'] = DataStatus.REJECTED;

        console.log('Reject doc button values: ' + JSON.stringify(values['id'])); 
        // , reason: values['reason']
        crudUpdate('order_assign', record.id, {driver_id: values['id']}, basePath, 'show', true);

        // dataProvider(UPDATE, 'vehicle_status', { id: record.id, data: {id: record.id, status: VehicleStatus.LOCKED, reason: values['reason']}})
        //     .then(({ data }) => {
        //         this.setState({ showDialog: false });
        //     })
        //     .catch(error => {
        //         showNotification(error.message, 'error');
        //     })
        //     .finally(() => {
        //         // Dispatch an action letting react-admin know a API call has ended
        //         fetchEnd();
        //     });

        this.setState({ showDialog: false });
    };

   

    render() {
        const { showDialog } = this.state;
        const { isSubmitting, record , classes} = this.props;

        
        // var id = record.id;
        let submit;
        return (
            <Fragment>
                <Button onClick={this.handleClick} label="Assigner à un chauffeur" style={{'background':'#00ad8f'}}>
                    <AssignIcon color='primary'/>
                </Button>
                <Dialog
                    maxWidth="800xs"
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Assigner"
                >
                    <DialogTitle>Assigner à un chauffeur</DialogTitle>
                    <DialogContent>
                    <Form
                            toolbar={null}
                            onSubmit={onSubmit}
                            render={({ handleSubmit, form, submitting, pristine, values }) => {submit = handleSubmit
                            return (
                                <form id="assign-form" onSubmit={handleSubmit}>
                                <div>
                                    <span>Choissez un chauffeur à assigner</span>
                                    {/* <TextInput multiline source="reason" fullWidth={true} validate={required()}/> */}
                                    <ReferenceInput label="Chauffeur" source="client_id" reference="favorite_driver"
                                    filter={{ client_id: record.client_id }}>
                                        {/* <AutocompleteInput optionText="last_name" classes={{
                                            suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                        }}/> */}
                                        <SelectInput optionText="last_name"
                                        />
                                    </ReferenceInput>
                                </div>
                                
                                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                                </form>
                            )
                            }}
                    >

                    </Form>
                    </DialogContent>
                    <DialogActions>
                        {/* <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        /> */}
                       
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                        <Button label="ra.action.save" onClick={() =>
                                // { cancelable: true } required for Firefox
                                // https://github.com/facebook/react/issues/12639#issuecomment-382519193
                                document
                                .getElementById('assign-form')
                                .dispatchEvent(new Event('submit', { cancelable: true }))
                            } saving={isSubmitting}>
                            <ActionSave />
                        </Button>
                        {/* <button
                            type="submit"
                            onClick={() =>
                                // { cancelable: true } required for Firefox
                                // https://github.com/facebook/react/issues/12639#issuecomment-382519193
                                document
                                .getElementById('exampleForm')
                                .dispatchEvent(new Event('submit', { cancelable: true }))
                            }
                            >
                            Save
                            </button> */}
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting()(state)
});

AssignButton.propTypes = {
    label: PropTypes.string,
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};


const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

// export default connect(mapStateToProps, mapDispatchToProps)(
//     LockVehicleButton
// );
// export default connect(undefined, { crudUpdate })(AssignButton);
export default connect(undefined, { crudUpdate })(withStyles(styles)(AssignButton));
    // handleClick = () => {
    //     // const { push, record, showNotification } = this.props;
    //     // const updatedRecord = { ...record, is_approved: true };
    //     // fetch(`/comments/${record.id}`, { method: 'PUT', body: updatedRecord })
    //     //     .then(() => {
    //     //         showNotification('Comment approved');
    //     //         push('/comments');
    //     //     })
    //     //     .catch((e) => {
    //     //         showNotification('Error: comment not approved', 'warning')
    //     //     });
    // }

    // render() {
    //     return (
    //             <Button label={this.props.label} onClick={this.handleClick}>
    //                 <ActionEmail/>
    //             </Button>
            
    //     );
    // }
// }

// SendMailButton.propTypes = {
//     label: PropTypes.string,
//     push: PropTypes.func,
//     record: PropTypes.object,
//     showNotification: PropTypes.func,
// };

// export default connect(null, {
//     showNotification,
//     push,
// })(SendMailButton);