export const  DRIVER_STATUS_FREE = "0";
export const  DRIVER_STATUS_BUSY = "1";
export const  DRIVER_STATUS_OFFLINE = "2";

export const UB_DOC_TYPE_RIB = '1';
export const UB_DOC_TYPE_KBIS = '2';
export const UB_DOC_TYPE_CVTC = '3';//Card VTC
export const UB_DOC_TYPE_CVTCR = '31';
export const UB_DOC_TYPE_CVTCV = '32';
export const UB_DOC_TYPE_VEHICLE_INSURANCE = '4';
export const UB_DOC_TYPE_RCP = '5';
export const UB_DOC_TYPE_ID_CARD = '6';
export const UB_DOC_TYPE_ID_CARD_RECTO = '61';
export const UB_DOC_TYPE_ID_CARD_VERSO = '62';
export const UB_DOC_TYPE_VEHICLE_PHOTO = '8';
export const UB_DOC_TYPE_VEHICLE_FRONT = '81';
export const UB_DOC_TYPE_VEHICLE_REAR = '82';
export const UB_DOC_TYPE_DRIVING_LICENSE = '9';
export const UB_DOC_TYPE_SHAREHOLDER_STATEMENT = '10';
export const UB_DOC_TYPE_COMPANY_STATUS = '11';
export const UB_DOC_TYPE_VTC_REGISTER_CERT = '12';
export const UB_DOC_TYPE_PHOTO = '13';
export const UB_DOC_TYPE_VEHICLE_GRAY_CARD = '16';
export const UB_DOC_TYPE_TRANSPORT_INSURANCE = '17';
export const UB_DOC_TYPE_VEHICLE_TYPE_MAP = '181';
export const UB_DOC_TYPE_VEHICLE_TYPE_SELECT = '182';
export const UB_DOC_TYPE_INVOICE = '19';
export const UB_DOC_TYPE_EMPLOYMENT_RELATIONSHIP_PROOF = '20';
export const UB_DOC_TYPE_PRO_CARD = '21';
export const UB_DOC_TYPE_YELLOW_CARD = '22';
export const UB_DOC_TYPE_CONTINUING_TRAINING_CERT = '23';
export const UB_DOC_TYPE_TRANSPORT_LICENSE = '24';
export const UB_DOC_TYPE_AUTO_INSURANCE_CERT = '25';
export const UB_DOC_TYPE_CPAM_APPROVAL = '26';

export const GENDER_MALE = "1";
export const GENDER_FEMALE = "2";

export const TRUE = 1;
export const FALSE = 0;

export const MODE_PAYMENT_CARD = "1";
export const MODE_PAYMENT_CARD_ON_BOARD = "4";
export const MODE_PAYMENT_CASH = "2";

export const USER_TYPE_CLIENT = 1;
export const USER_TYPE_AUTO_ENTERPRENER = "2";
export const USER_TYPE_COMPANY_AND_DRIVER = "6";
export const USER_TYPE_DRIVER_IN_COMPANY = "7";
export const USER_TYPE_DRIVER_COMPANY = "246";

export const ACTIVITY_TYPE_TAXI = "1";
export const ACTIVITY_TYPE_VTC = "2";
export const ACTIVITY_TYPE_TPMR = "4";

export const MODE_DISPATCH_DRIVER_FAVORITE = "1";
export const MODE_DISPATCH_DRIVER_CHOSEN = "2";
export const MODE_DISPATCH_AUTO = "3";

// export const STATION = 1;
// export const AIRPORT = 2;
// export const AIRPORT_TERMINAL = 4;
// export const PLACE = 3;
// export const REGION = 5;
// export const CLUBS = 8;
// export const BOOK = 6;
// export const RECENT = 7;

export const weekDays = [
    'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'
];

export const unitTypes = [
    {id: 0, name: '€'},
    {id: 1, name: '%'}
];

export const markupTypes = [
    {id: 0, name: 'Majoration (+)'},
    {id: 1, name: 'Remise (-)'},
    
  ];

export const OrderType = {
    IMMEDIATE: "0",
    RESERVATION: "1",
};

export const OrderSubType = {
    NO_SHARING: "4",
    SHARING: "8",
};

export const orderSubTypes = [
    {id: OrderSubType.NO_SHARING, name: "Non"},
    {id: OrderSubType.SHARING, name: "Oui"},
];

export const orderTypes = [
    {id: OrderType.IMMEDIATE, name: "Immédiate"},
    {id: OrderType.RESERVATION, name: "Réservation"},
];

export const OrderStatus = {
    CREATED: 1 << 0,
    ASSIGNED: 1 << 1,
    DENIED: 1 << 2,
    ACCEPTED: 1 << 3,
    TO_PICK: 1 << 4,
    AT_PICK: 1 << 5,
    EXTEND_WAIT: 1 << 6,
    ABSENT: 1 << 7,
    ONBOARD: 1 << 8,
    STARTED: 1 << 9,
    ENDED: 1 << 10,
    FINISHED: 1 << 11,
    CANCELLED: 1 << 12,
    UNPAID: 1 << 13,
    DELETED_BY_CLIENT: 1 << 14,
    DELETED_BY_DRIVER: 1 << 15,
    RATED_BY_CLIENT: 1 << 16,
    RATED_BY_DRIVER: 1 << 21,
    ACCEPTED_BY_OPERATOR: 1 << 17,
    DENIED_BY_OPERATOR: 1 << 18,
    WAIT_PAYMENT: 1 << 20,
}
export const LocationType = {
    STATION: 1,
    AIRPORT: 2,
    PLACE: 3,
    AIRPORT_TERMINAL: 4,
    REGION: 5,
    BOOK: 6,
    RECENT: 7,
    CLUBS: 8,
    FAV: 9,
    HOTEL: 10,
    RESTAURANT: 11,
    HOSPITAL: 12,
    BEACH: 13,
    TOURISTIC_SITE: 14,
    HISTORICAL_SITE: 15,
    SCHOOL: 16,
    MALL: 17,
}

export const DataStatus = {
    NEW:"0",
    APPROVED:"1",
    REJECTED:"2",
    
}

export const Status = {
    UNLOCKED:"0",
    LOCKED:"1",
}

// Date.prototype.addDays = function(days) {
//     var date = new Date(this.valueOf();
//     date.setDate(date.getDate() + days;
//     return date;
// }

// var date = new Date(;