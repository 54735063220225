import React from 'react';
import Typography from '@material-ui/core/Typography';
import { GENDER_MALE } from '../../util/constant';

export const GenderFilterField = ({ record }) => {
    return <span>{record.id === GENDER_MALE ? 'Monsieur' : 'Madame'}</span>;
};

const GenderField = ({ record = {}, label}) => {
    return <div>
         <Typography variant="body1">
        {record.gender == GENDER_MALE? 'Monsieur' : 'Madame'}
        </Typography>
    </div>;
}

GenderField.defaultProps = {
    addLabel: true,
};

export default GenderField;
