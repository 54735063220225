import React from 'react';

const Logo = props => (
  <svg version="1.1" id="Layer_1" 
  xmlns="http://www.w3.org/2000/svg" 
  
  x="0px" y="0px" width="175px" height="50px" viewBox="0 0 175 50" enable-background="new 0 0 175 50" 
  
  >  <image id="image0" width="175" height="50" x="0" y="0"
    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK8AAAAyCAYAAAAqaovoAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A
/wD/oL2nkwAAAAlwSFlzAAALEgAACxIB0t1+/AAAAAd0SU1FB+QMEgggL06nyWIAAA9ESURBVHja
7VxpWBRXuv4Kmk1BUEBUoriLG4oQNRgDmqioydVMZKKTeJMZfUw0iVlMRs1co89MEk3UmETjFTVD
iIliN4gIsojSIKI0dkOz78jSbN1N7/t27g8lU11U082SVOvt93m+H1XnPW993zkvxaHOUQwhBA44
8DjCieoEHHBgsHCY14HHFjSqEyBD2uX56yaDZM84UBhFmMfdejQ2b/2rXCbVeTlgX7BL8/o5qYNn
mzpXOoMJfJF81UzggzLBS1QNAX8L39yQQnV+DtgH7HLZIAEPMIArANDACC5gAhqMxNRjwrHGq+2X
/Y9QnZ8D9gG7NO9EELcaAYQAGAAAmAADPbgAAlcIBOHeNMbC16jO0QHqYZfmvQLzypsx3woA3W/3
MAAwPkp3EWr9gOocHaAedmneAzGZtTxsVCoggN6373/gCuORIvxMQtRkqvN0gFrYpXkBAO66Tz0j
wHwENFAD3sBGcAIMECxxbp1hTeMtDsflzyUZ/gtuXBh7qDnXneqaHBheYPa8w/ZD4ool7xhzCwEA
TOAGABiYHqYNNKR+BTajK3j+uBsXRm4ZH7Txeb+J62d4+Sz0d/UY70VzHeXkhDkp9TpFj07Lb1CK
yzO7W29l97Qkl0W9zqO6RgeGAISQXQeLHjSu9bJfPEpwNaEEGjIlOCN9ghtCl+BPvRxIjvP5qbX6
ZzRApHU23Zxz88IcqmscSkBsrEtKR8OB7O6WQwFZR0cOl+7n1aw32aKuI4tvJSygukaLtVOdgK3R
Tff3rLwycQMvccxnKBHOZifOmNrbVi7prh2ocfE411wZD/SDrlTXOJg4XMP6Um8yIoPJhE7Wl3w3
HJprCpLW92hUcqPJhO4JOwpG0Y+PobrOx9q8luJAFeuZoRi3Fx0alSAg5fspVNczkJh/68IsYh3v
VzAXDlWXr1O34jUvtNZ8RnWtZGGXO2xkoB06FIV1ysIMQPMA5GKEOV6X0Af7mu+I2vkmQOBE+CrR
opS1cWVCjp+rewdggNpVct/53mNDZ3uNnkWmP97Nw48dvf2eF+P7+fKY3QKq67UFnkDzJd7bM+3p
gKHq0gDT4a8XjBrjRXWtpKD6p8dajDh1cF3w8rXisJnL0NOTl6KnJy1Gy0aHoICPdr3Zy3mrOHtX
71vi5+bqi5HMpHmW9FYUJAYdrWV/JdNplGRv4Gq5pAmufuVFdd22RCjzYjgxf7a4c8VQddtViiq8
Zr6g/XOqa33s3rwjz36xdt6R9OsYQqDx9wI0EgNACEyuNFA+5V3fyzsT+sLpP1fm/qyVSLGUZRvk
W4OCLWrmRLzSAgB7MQbjU0ZEKGNT4PSX8e3Bnt5TMhZvSgGAlVTXPxgYqE7gD4TdfucFAJh0mXUc
MyFQjfMGhD1cFrjItaAZ68VXPBVaiOfS50YpUpZtkNuqjWJijJsCp//ps+p7XxDbosdPXbGtKPVt
quunCiZkv59P8bBb83qePLTSq0kwWz3WCzDTo8HEMHDrUYBo8dR4FBNjHI7n/HP2M/9zuKboBPH+
gTnPHcAOHRrw+PyFk+u3uzQvdHfF7Yiva+7PmpB6dsRQczxVXeSby28eF84564K/j4Fx0L85txUU
eNWpegK31RT0Wc+6OTlhg9H8w0H1usVSBLy/c/+S8WEoJHQlCgldiULnPIcifELQzOdfegB0+rB/
1soX8oqI68dNd66+Zmv/d0uZb5ZIBByd0WDCa8h1WnlGV3N6JDNh6UDyiWBeDE/paEzoVMvbNAa9
QW8yIpFWLeKIu+/t5jLfRggBXIsdQcy5sJ8175Sb5wKO13MO1yvEVUq9TmU0mZBSr1M3KsTVpxu5
X41MOjoWIQQqg577OKx5KU+ANKnYHS6Tt7z2vxGjQ1D4lKVo0YxlaOG8SDRx69YrwIxz/z2eGZXz
6xqiERhtNSnW+gVfPzvzfk/XfWQDzjVyY63WHnfQ/XJb9SVrWlyJoOSTsvw9Cr3O7A9PS+bdW5a3
T2806vvTVOh1uk/K8z6plokKHeYdQvju/yjY98Ndb/nu2/32uPfe2uRx9uBTlrgbi9Ij6bzaC40K
cWWHRtnVpVF2V0lFZQxe7S+vFKavtvWZhcK2YvykCdRK6dxbP02zxJ+ZGRuMEDJ701pDjpCXY0lv
NP2Id6ta1jwQPb3RaLBm3qT2ussD0TSazEtymPd3iDlZcQu5EkGltckoEfMr52ecn2VNb19Z7kFi
37/ez9pkic/TKHhEvgEhA1vUXVgo7MwolwjKyPL5rIZ1gEzvvlTItlRDt1op6tGopNZqJZr306rC
/Za4PRqVlK9WiKxpOsw7zPFyQdJma4NOxBuFadv609zMSosm9vnxQcU+Mu6eijsfErlHalnfel47
5ofnBV2LDc4RtN0mcgOyTo/F83aUMreT5fx3LnP/8jz6FIQQADvWZWVe0qLzDyrOW6qxXC74zbyP
dgz7IK65/N8r85IWATvWBSEE6woSg/aX5v7dkqbDvMMYS7Ljn7VmVEtYceuSxWXEf7My5hL5LFHn
N2TcDrW8Hc/bW3HnRH85F/Z0NuD5/6opMtty5WtV3fh2I0Kov0ND75TmvUNWX5ta/pt5f3hQdo7Y
/l7Z7fcsac69ET/bgIx9lkEO8w5jFAk7uGQT16KSCkok3dealJJUrphfrTHoDUQOW9RVaEn3VXb6
NCK/SSE9TeRFMhOD8Ry+RiWxlvOGwtTlBtxa8q6wM7+3LYJJX0h87rvcnN3WNG92t94i9hOo1Sse
TSzWrlZ24tuyH/L71dz18LkO8/4esYt9Yw+Zcf/GvvExnued9M0iMl6HRtFgSTvmfupiIj+XzztM
5P2jomALnqMy6FUqvb5SqdfXNSrF1XUycXmFVFDKFXZwG+USNkLoXotSysR/RhNrNT1hj35tn6jn
/MPMgBq1yJaxeD6fEUXMt/fNG5wdP5PYFp1/5VlbdCVatdna2l7Na9fbw2TYOW3hduK9Lay0bZeW
vPjv3msvxpf+xevevUbW/5tadtzRkEhSbTcjNp94L1/I40X6B5rdk+t1ZgdiPJxpHgAwBwBgKs2H
VHvSiFFm1z6ubmNGawL8AaBjtteYBfi2NpWs2M/N+j/8EKjUVQgQYLhDSVqDwQkA4Oi85ybguQgQ
NGvk1baMcZNKVhrq6r6895rmhNnlpoXd7rCR4ZlcxvR5Pv5mBxeyux7cxRvX89J3AaUv7q6cMXJU
ILH/d43ck0dDIr+wpL/Kf+Jq4r1sflsZ8Z6/u4d+OOpplskVAABuzs5m84AwpLWlvwG5G4gbucVi
gQEAINTHz+zFZDIhpFaYbNqVxDDM7Plao8Fh3qEi3Nd/MvHePQn/Ov769totN6d6ePoTeZ/Xsr54
f9rC3Za0Pa8cG/vKpNlmh3SKxd0Vt1UdRUSu0qBrN7/Wq0pE3cXNKnlDu0rRxNcqW0U6DU+u1/E1
Br1IrtfK5HqtXGXQqzRGg0am10q3FmX+tX7t6zIAgAdKaT1eb4yLxzxbxsPfEyYRj4KeauLwAQAO
VBeYHet0dnLCnhrlFmiLrp+r+2z8dblCJBr4bP0BoHrdMpA4Vsd+kbiO+6qOvau3fScnJ4xsnftt
Q/Epa9rvc27sJfY7XHn3OBl3UsaF8XieXKeV96NNC2LGuQP9uEckk0l7g8l0j05Pd8NzYgqvryQ+
O+wmfbH18eAcw/eR6TQyiH24jgb6cQ+lXq/Ctx+puf+1Nc2wnItLiLmsyU+OonruyYLyBAYSL+Wn
LCUO7LEa1sne9uV59CkGwu7Qmcays9Z0vZNP+PB1mj4bAHOy4hZa6nNHwMvHc1M7G9MHPQnMgzSZ
TiPD6zXKJY399QnPvhhKzPdCa3U8nsNoq0sgcpbk/BrWn26jQmz2SU+oUQsAIYzquX/szRvEjPOR
6jQa/OC2aJQCiD04opezjZ25s7ftSC37iC261zqasomTzGit6vdcw5qC5D5nIejtDQyIO0h69mIr
O/svNXJRTZGo896E5B8mEtsP1RQdJOpxpMLi6emxfbanVzPp0caHn4LNMDMzLhjPC8nta3CEkCk6
N2EdUXNS2umpZDt8H1cWfIz+oPl9os2LEIIUXkMmcYB/bCr/Bc/ZwEz2WZ+fNtoWvY9Kcz4lmWA0
O/18qLW+17ta0oj9ZHqd6GR98ZkzTWU74psrt/6ruvCfHFGXmSk6NSpeTIX5yTig051bNIpWslxy
+K05Vzsav09oqz3P6eksIeOcaOCSbqbEtlTGkvG5oq6SRF7dueSOhpM53a05ZJw6hayW6vl+oswb
nvlzBNlAv1d8a8BviBdyLq4n0zpSdfdbWzUqZT2laBD4sPWuB1FrUtI346V6nXigWsmdjVf6y/Gm
oC1zoJoCrUbgdeWkL9Xz3V9QnsBg4kw9J45swNfkMTbYqhGY+u0MFTL22YFj9XSWDmgAY2Ndsjqb
MgZijJ2crB2W9CannQoqIbyp+8P5Ju45W/KMe1D+k62aRaIu1qSk2PFUz7PVsac6gUElTT/u0aiQ
9jnRhRBCa28nbrTWf3p67LRurUZA7GtCCE1LOTt3MDm9dj99U76Ql2fJECKNSvJLS9WPYZm/Btui
t42d9cZdYcdtjUHf56yBWKuWJrc3XF51O3HZQHJclZf4XFJ73WUxYQcNIYQ0BoOpQMi7/UZRxlaq
59fWsOv/7qk/zMo4H1K7dnspWVtMYcoWxtINCWRtITfiw1gvvM7ycHJ2Jra9fCfx1eRnN9GHktdL
BanPvDhhcnjEmHETg0aO8i6TCIW3hK1VyV0tBdyozc0D1XuVlbUg2Ms72NUJ8zYghBR6Hb9cJSrN
XLpxwFq9iC68Onn+iDELPF1cx9IwDNOZkLRGLq25vGRN6WA1KQHVPz1DCUtrVoQQ+qA8r88aODo/
+SVL/MGsmR1B8W9gqhMYaiy98WukJUOmdDVd7+V9Wcf52hJvByf7I6rrcMT/Q/M+NHC8RQPf6Wln
Xe98kGWpfXs/fzw5wr6D8gSGK9bkJPTZNLCGt4uzrZ6ZdYT9BuUJDGdMSP5hYrVc0mTNtGqDwbAy
n7GW6nwdMbR4bL829AdGe2NyTOC0jWRt96XCslV3GFGS9TvFVOfpwBBB9U/P7xXbOdk7iB9Iv3x4
Covy3BzhePNahV/K116JEa9nTfAYGfhfdxJX10Rvq6U6JweGD0+0eQEAXi5IXT1qhLtvfOiqS1Tn
4sDw4ok3rwNPLh6rfwbkgAN4OMzrwGOL/wPM4TECvJ6trgAAATJlWElmTU0AKgAAAAgABwESAAMA
AAABAAAAAAEaAAUAAAABAAAAYgEbAAUAAAABAAAAagEoAAMAAAABAAIAAAExAAIAAAAgAAAAcgEy
AAIAAAAUAAAAkodpAAQAAAABAAAAqAAAANQAAABIAAAAAQAAAEgAAAABQWRvYmUgUGhvdG9zaG9w
IENTNiAoTWFjaW50b3NoKQAyMDIwOjEyOjE4IDE1OjMxOjQ0AAAAAAOgAQADAAAAAQABAACgAgAE
AAAAAQAAAK+gAwAEAAAAAQAAADIAAAAAAAAABgEDAAMAAAABAAYAAAEaAAUAAAABAAABIgEbAAUA
AAABAAABKgEoAAMAAAABAAIAAAIBAAQAAAABAAABMgICAAQAAAABAAAAAAAAAAAAAABIAAAAAQAA
AEgAAAABykQKDQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0xMi0xOFQwODozMjo0NyswMDowMG8D
WmwAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTItMThUMDg6MzI6NDcrMDA6MDAeXuLQAAAAKXRF
WHRkYzpmb3JtYXQAYXBwbGljYXRpb24vdm5kLmFkb2JlLnBob3Rvc2hvcOStn1QAAAA4dEVYdGlj
Yzpjb3B5cmlnaHQAQ29weXJpZ2h0IChjKSAxOTk4IEhld2xldHQtUGFja2FyZCBDb21wYW55+Vd5
NwAAACF0RVh0aWNjOmRlc2NyaXB0aW9uAHNSR0IgSUVDNjE5NjYtMi4xV63aRwAAACZ0RVh0aWNj
Om1hbnVmYWN0dXJlcgBJRUMgaHR0cDovL3d3dy5pZWMuY2gcfwBMAAAAN3RFWHRpY2M6bW9kZWwA
SUVDIDYxOTY2LTIuMSBEZWZhdWx0IFJHQiBjb2xvdXIgc3BhY2UgLSBzUkdCRFNIqQAAABV0RVh0
cGhvdG9zaG9wOkNvbG9yTW9kZQAzVgKzQAAAACZ0RVh0cGhvdG9zaG9wOklDQ1Byb2ZpbGUAc1JH
QiBJRUM2MTk2Ni0yLjEcL2wLAAAAE3RFWHR0aWZmOlhSZXNvbHV0aW9uADcyDlBxhQAAABN0RVh0
dGlmZjpZUmVzb2x1dGlvbgA3MpNfkPMAAAAodEVYdHhtcDpDcmVhdGVEYXRlADIwMjAtMTItMThU
MTU6MzE6MTQrMDc6MDBz8YZyAAAAL3RFWHR4bXA6Q3JlYXRvclRvb2wAQWRvYmUgUGhvdG9zaG9w
IENTNiAoTWFjaW50b3NoKesMDgkAAAAqdEVYdHhtcDpNZXRhZGF0YURhdGUAMjAyMC0xMi0xOFQx
NTozMTo0NCswNzowMLNL55EAAAAodEVYdHhtcDpNb2RpZnlEYXRlADIwMjAtMTItMThUMTU6MzE6
NDQrMDc6MDCP77QvAAAAOXRFWHR4bXBNTTpEb2N1bWVudElEAHhtcC5kaWQ6QTJDQjA1NDJBRTIw
NjgxMTgwODNEQzgxQTA0NTE1NDejSIcCAAAAOXRFWHR4bXBNTTpJbnN0YW5jZUlEAHhtcC5paWQ6
QTVDQjA1NDJBRTIwNjgxMTgwODNEQzgxQTA0NTE1NDc27d2AAAAAQXRFWHR4bXBNTTpPcmlnaW5h
bERvY3VtZW50SUQAeG1wLmRpZDpBMkNCMDU0MkFFMjA2ODExODA4M0RDODFBMDQ1MTU0NwqypcIA
AAAASUVORK5CYII=" />
</svg>
);

export default Logo;
