import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import OrderIcon from '@material-ui/icons/TouchApp';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { translate } from 'react-admin';
import Button from '@material-ui/core/Button';
import CardIcon from '../../control/view/card-icon';

const styles = {
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
        height: 100,
    },
    title:{
        fontSize: '0.8rem',
    }
};

const OrderUnassignedNbDashboard = ({ value, translate, classes }) => (
    <div className={classes.main}>
        <CardIcon Icon={OrderIcon} bgColor="#afe" />
       
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
                <Link href="#/order_unassigned">
                {translate('dashboard.orders.unassigned')}
                </Link>
    
            </Typography>
            <Typography variant="headline" component="h2">
                {value}
            </Typography>
            
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(OrderUnassignedNbDashboard);
